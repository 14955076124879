import React from 'react';
import styled from '@emotion/styled';
import { Bank } from '@sumup/icons';
import { useTranslation } from 'react-i18next';
import PreviewTitle from '../../previewTitle/previewTitle';
import { Body } from '../../body';

const BankIcon = styled(Bank)`
	${({ theme }) => `
		color: var(--cui-fg-subtle);
		align-self: center;
		width: auto;
		height: ${theme.typography.body.one.fontSize};
	`}
`;

const PreviewLine = styled(Body)`
	${({ theme }) => `
		display: flex;
		gap: ${theme.spacings.byte};
		margin: ${theme.spacings.byte} 0 0;
	`}
`;

const DirectDebitPreview = ({ maskedIban, mandateReference }) => {
	const { t } = useTranslation();
	return <div data-testid='direct-debit-preview'>
		<PreviewTitle size="two">{t('payment_method.direct_debit.label')}</PreviewTitle>
		<PreviewLine>
			{t('payment_method.direct_debit.iban.title')}: {maskedIban} <BankIcon />
		</PreviewLine>
		<PreviewLine>
			{t('payment_method.direct_debit.iban.mandate_reference')}: {mandateReference}
		</PreviewLine>
	</div>;
};

export default DirectDebitPreview;
