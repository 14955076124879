export const DESKTOP_BREAKPOINT = '(min-width: 768px)';
export const DESKTOP_BREAKPOINT_ABSOLUTE_VALUE = 768;
export const PAYMENT_METHODS = {
	creditCard: 'creditCard',
	directDebit: 'directDebit',
	businessAccount: 'businessAccount',
};
export const VIEW_TYPES = {
	purchase: 'PURCHASE',
	changeInfo: 'CHANGE_INFO',
	changePlan: 'CHANGE_PLAN',
};
export const PAYMENT_SERVICE_PROVIDER = 'SumUp UK Payments Ltd';
export const EPAY_CARD_PREFIX = 'SU1R';