import wrapper from './api';
import { trace } from '../utils/notifyParent';
import { ApiError, InitializationError, SumUpError } from '../utils/errors';
import { TRACING_EVENTS } from '../../shared/constants';

function sumupService({ sumupBaseUrl }) {
	if (!sumupBaseUrl) {
		throw new InitializationError('SumUpBaseUrl should be provided');
	}
	const onError = (error => {
		const serviceIsUnavailable = error instanceof SumUpError
			&& (error.isNetworkError() || error.isHTTPBadGateway() || error.isHTTPServiceUnavailable());
		const details = error instanceof ApiError && error.details;
		if (serviceIsUnavailable) {
			trace(TRACING_EVENTS.SUMUP_REQUEST_BLOCKED, {
				details,
			});
		}  else {
			trace(TRACING_EVENTS.SERVER_ERROR, {
				type: 'SumUp service',
				reason: error.message,
				details
			});
		}
	});

	const api = wrapper({
		request: {
			baseURL: sumupBaseUrl,
			withCredentials: false,
			headers: {
				'Content-type': 'application/json',
			},
			errorHandled: true,
		},
		error: SumUpError,
		onError,
	});

	async function processCheckout({ checkoutId, cardholderName, cardnumber, expiryMonth, expiryYear, cvv, mandate }) {
		const url = `/checkouts/${checkoutId}`;

		return api.put(url, {
			payment_type: 'card',
			mandate,
			card: {
				name: cardholderName,
				number: cardnumber,
				expiry_year: expiryYear,
				expiry_month: expiryMonth,
				cvv,
			},
		}).catch((err) => {
			trace(TRACING_EVENTS.SCA_VERIFICATION_FAILED, { reason: err.message });

			throw err;
		});
	}

	return {
		processCheckout,
	};
}

export default sumupService;
