import { InternalError } from './errors';

export function formatDate(locale, dateString) {
	if (!locale) {
		throw new InternalError('locale should be provided to formatDate');
	}
	if (!dateString) {
		throw new InternalError('dateString should be provided to formatDate');
	}

	const options = { year: 'numeric', month: 'long', day: 'numeric' };

	const dateObj = new Date(dateString);
	if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
		throw new InternalError(`Cannot create Date from ${dateString} - Invalid date`);
	}

	return new Intl.DateTimeFormat(locale, options).format(dateObj);
}
