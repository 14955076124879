import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { RadioButtonGroup } from '@sumup/circuit-ui';
import { useTranslation } from 'react-i18next';
import { InputRow, InputCol } from '../../input';
import { PAYMENT_METHODS } from '../../../constants';

const StyledRangedButtons = styled(RadioButtonGroup)`
	${({ theme }) => `
		border: none;
		padding-left: 0;
		margin: 0;
		& > div {
			margin-bottom: ${theme.spacings.mega};
		}
	`}
`;

const PaymentMethodSelector = ({
	onChange,
	initialValue = PAYMENT_METHODS.creditCard,
	availablePaymentMethods,
	businessAccountEnabled,
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(initialValue);
	const { directDebit, businessAccount } = availablePaymentMethods;

	return <div data-testid="payment-method-selector">
		<InputRow>
			<InputCol>
				<StyledRangedButtons
					label={t('payment_method.choose_payment_method')}
					name="payment-method-selector"
					value={value}
					onChange={({ target }) => {
						setValue(target.value);
						onChange(target.value);
					}}
					options={[
						{
							label: t('payment_method.credit_card.label'),
							value: PAYMENT_METHODS.creditCard,
							'data-testid': 'credit-card-payment-method',
						},
						...(directDebit ? [{
							label: t('payment_method.direct_debit.label'),
							value: PAYMENT_METHODS.directDebit,
							'data-testid': 'direct-debit-payment-method',
						}] : []),
						...(businessAccount ? [{
							label: t('payment_method.business_account.label'),
							value: PAYMENT_METHODS.businessAccount,
							'data-testid': 'business-account-payment-method',
							disabled: !businessAccountEnabled,
						}] : []),
					]}
				/>
			</InputCol>
		</InputRow>
	</div>;
};

PaymentMethodSelector.propTypes = {
	onChange: PropTypes.func.isRequired,
	initialValue: PropTypes.string,
};

export default  PaymentMethodSelector;
