import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { countryRegions } from '../../constants/country';
import { useTranslation } from 'react-i18next';
import { Select } from '../select';

const getRegionsForCountry = country => countryRegions[country] || [];

const StyledSelect = styled(Select)`
	margin-bottom: ${({ theme }) => theme.spacings.mega};
`;

const RegionSelect = ({ country, value, onChange, name, ...props }) => {
	const { t } = useTranslation();
	const [regions, setRegions] = useState([]);
	const [regionOptions, setRegionOptions] = useState([]);
	const handleChange = (event) => {
		const { value } = event.target;
		const id = parseInt(value);
		const region = regions.find(region => region.regionId === id) || { regionId: null, regionName: null };
		Object.assign(event, { region });
		onChange(event);
	};
	useEffect(() => {
		const newRegions = getRegionsForCountry(country);
		const options = newRegions
			.map((region) => ({
				label: region.regionName,
				value: region.regionId,
			}));
		setRegions(newRegions);
		setRegionOptions(options);
	}, [country]);

	return <>
		<StyledSelect
			options={regionOptions}
			placeholder={t('region.field.placeholder')}
			id={name}
			value={value}
			{...{ ...props, name }}
			onChange={handleChange}
		/>
	</>;
};

export default RegionSelect;
