import { MESSAGE_TYPES, TRACING_EVENTS } from '../../shared/constants';
import { SDKError } from './errors';
import queryParameters from './queryParameters';

const { id } = queryParameters;

const availableTracingEvents = new Set(Object.values(TRACING_EVENTS));

export const notifyParent = (message) => {
	try {
		const msg = { type: MESSAGE_TYPES.WIDGET_MESSAGE, id, action: 'message', ...message };
		window.parent.postMessage(msg, '*');
	} catch (e) {
		const error = new SDKError(e.message);

		error.propagate();
	}
};

export const trace = (type, data = {}) => {
	if (!availableTracingEvents.has(type)) {
		return;
	}

	notifyParent({
		message: MESSAGE_TYPES.TRACE_EVENT,
		payload: { type, data },
	});
};
