import styled from '@emotion/styled';
import { Col, Row } from '@sumup/circuit-ui/legacy';
import { Input } from '@sumup/circuit-ui';
import { withNoMargin } from '../hocs';

const InputRow = styled(Row)`
	display: flex;
	flex-flow: row nowrap;
	align-items: start;
`;
const InputCol = styled(Col)`
	flex: 1;
`;

const NoMarginInput = withNoMargin(Input);

export { InputRow, InputCol, NoMarginInput as Input };