import React from 'react';
import { InputRow, InputCol } from '../../input';
import CardholderNameInput from './cardholderName';
import CardNumberInput from './cardNumber';
import ExpiryDateInput from './expiryDate';
import SecurityCodeInput from './securityCode';
import { Field } from 'react-final-form';
import {
	composeValidators,
	minLength,
	required,
	expiryDateValidator,
	cardNumberValidator,
	preventInjection,
} from '../../../utils/form-validators';
import i18n from '../../../i18n';

class CreditCard extends React.Component {
	state = {
		cardholderName: '',
		cardnumber: '',
		expiryMonth: '',
		expiryYear: '',
		cvv: ''
	};

	onChange(fieldName, value) {
		const cb = () => this.props.onChange(this.state);

		if (fieldName === 'expiryDate') {
			const [expiryMonth, expiryYear] = value;

			this.setState({
				expiryMonth,
				expiryYear
			}, cb);
		} else {
			this.setState({
				[fieldName]: value
			}, cb);
		}
	}

	render() {
		const { disabled } = this.props;

		return (
			<div data-testid="credit-card">
				<InputRow>
					<InputCol>
						<Field
							name="cardholderName"
							validate={composeValidators(
								required(i18n.t('payment_method.credit_card.cardholder.field_name')),
								preventInjection,
							)}
							defaultValue={this.state.cardholderName}
						>
							{({ meta, input }) => (
								<CardholderNameInput
									invalid={Boolean(meta.touched && meta.error)}
									validationHint={meta.touched && meta.error}
									{...input}
									value={this.state.cardholderName}
									disabled={disabled}
									onChange={(v) => {
										this.onChange('cardholderName', v);
										input.onChange && input.onChange(v);
									}}
								/>
							)}
						</Field>
					</InputCol>
				</InputRow>
				<InputRow>
					<InputCol>
						<Field
							name="cardNumber"
							validate={composeValidators(required(i18n.t('payment_method.credit_card.card_number.field_name')), minLength(15), cardNumberValidator)}
							defaultValue={this.state.cardnumber}
						>
							{({ meta, input }) => (
								<CardNumberInput
									disabled={disabled}
									invalid={Boolean(meta.touched && meta.error)}
									validationHint={meta.touched && meta.error}
									{...input}
									value={this.state.cardnumber}
									onChange={(v) => {
										this.onChange('cardnumber', v);
										input.onChange && input.onChange(v);
									}}
								/>
							)}
						</Field>
					</InputCol>
				</InputRow>
				<InputRow>
					<InputCol>
						<Field
							name="expiryDate"
							validate={composeValidators(expiryDateValidator)}
							defaultValue={[this.state.expiryMonth, this.state.expiryYear].join('/')}
						>
							{({ meta, input }) => (
								<ExpiryDateInput
									disabled={disabled}
									invalid={Boolean(meta.touched && meta.error)}
									validationHint={meta.touched && meta.error}
									{...input}
									value={[this.state.expiryMonth, this.state.expiryYear]}
									onChange={(v) => {
										this.onChange('expiryDate', v);
										input.onChange && input.onChange(v.join('/'));
									}}
								/>
							)}
						</Field>
					</InputCol>
					<InputCol>
						<Field
							name="securityCode"
							validate={composeValidators(required(i18n.t('payment_method.credit_card.cvv.field_name')), minLength(3))}
							defaultValue={this.state.cvv}
						>
							{({ meta, input }) => (<SecurityCodeInput
								disabled={disabled}
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								value={this.state.cvv}
								onChange={(v) => {
									this.onChange('cvv', v);
									input.onChange && input.onChange(v);
								}}
							/>
							)}
						</Field>
					</InputCol>
				</InputRow>
			</div>);
	}
}

export default CreditCard;
