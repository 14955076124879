const handlers = new Map();
const eventsBuffer = new Map();

window.addEventListener('message', (event) => {
	if (!event
     || !event.data
     || !event.data.type) {
		return;
	}
	const { type, data } = event.data;
	if (handlers.has(type)) {
		for (let handler of handlers.get(type)) {
			handler(data);
		}
	} else {
		!eventsBuffer.has(type) && eventsBuffer.set(type, []);
		const queue = eventsBuffer.get(type);

		queue.push(data);
	}
});

const addMessageListener = (type, handler) => {
	if (!handlers.has(type)) {
		handlers.set(type, new Set());
	}
	const typeHandlers = handlers.get(type);
	typeHandlers.add(handler);

	if (eventsBuffer.has(type)) {
		for (const data of eventsBuffer.get(type)) {
			handler(data);
		}
	}

	return () => removeMessageListener(type, handler);
};

const removeMessageListener = (type, listener) => {
	if (handlers.has(type)) {
		handlers.get(type).delete(listener);
	}
};

export default {
	addMessageListener,
	removeMessageListener,
};
