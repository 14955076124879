import { observable, action, makeObservable } from 'mobx';
import { InitializationError } from '../utils/errors';

class PriceDetails {
	isLoaded = false;

	customerCountryCode;
	planId;
	subscriptionId;
	lines = [];
	productName;
	planName;
	planFrequency;
	nextChargeDate;
	currentChargeDate;
	couponCode;

	constructor({ sumupService, billingService, customerCountryCode, vatNumber, planId, subscriptionId, couponCode }) {
		makeObservable(this, {
			isLoaded: observable,
			customerCountryCode: observable,
			planId: observable,
			subscriptionId: observable,
			lines: observable,
			productName: observable,
			planName: observable,
			planFrequency: observable,
			nextChargeDate: observable,
			currentChargeDate: observable,
			couponCode: observable,
			fetchPriceDetails: action
		});

		if (!sumupService || !billingService) {
			throw new InitializationError('sumupService and billingService should be provided');
		}
		this.sumupService = sumupService;
		this.billingService = billingService;

		Object.assign(this, {
			customerCountryCode, planId, vatNumber, subscriptionId, couponCode,
		});

		this.fetchPriceDetails();
	}

	async fetchPriceDetails() {
		if (this.isLoaded) {
			return;
		}

		try {
			this.isLoaded = false;
			const getPriceDetails = this.subscriptionId
				? this.billingService.getUpdateSubscriptionPriceDetails
				: this.billingService.getSubscriptionPriceDetails;
			const result = await getPriceDetails({
				planId: this.planId,
				subscriptionId: this.subscriptionId,
				vatNumber: this.vatNumber,
				customerCountryCode: this.customerCountryCode,
				couponCode: this.couponCode,
			});
			Object.assign(this, result);
			this.isLoaded = true;
		} catch (err) { // ingore error
			err.propagate();
		}
	}
}

export default PriceDetails;
