import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Markdown from 'react-markdown';
import { Body } from '../body';

const ALLOWED_ELEMENTS = ['p', 'a'];

const StyledText = styled(Body)`
	${({ theme }) => css`
		color: var(--cui-fg-subtle);
		margin: ${theme.spacings.tera} 0 0;
		a {
			color: var(--cui-fg-normal);
			font-weight: bold;
			text-decoration: none;
		}
		p {
			margin: 0;
		}
	`}
`;

const LegalInfo = ({ source, isMobile }) => {
	return (
		<StyledText as='div' isMobile={isMobile} data-testid="legal-info">
			<Markdown
				linkTarget="_blank"
				allowedElements={ALLOWED_ELEMENTS}
			>
				{source}
			</Markdown>
		</StyledText>
	);
};

export default LegalInfo;
