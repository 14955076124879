import React, { useContext, useState, useEffect, useCallback } from 'react';
import ProcessingStatus from '../components/processingStatus';
import useBreakpoint from '../utils/useBreakpoint';
import ScrollTop from '../components/scrollTop';
import { Container } from './Container';
import Customer from '../stores/Customer';
import WidgetConfigContext from './WidgetConfigContext';
import PurchaseSubscription from './PurchaseSubscription';
import ChangePaymentDetails from './ChangePaymentDetails';
import ErrorBoundary from './ErrorBoundary';
import ChangePlan from './ChangePlan';
import useResize from '../utils/useResize';
import {
	VatUpdateError,
	BillingDetailsUpdateError,
	MissingCustomerError,
	MissingCustomerSubscriptionError,
	SumUpError,
	PurchaseSubscriptionError,
	ChangePaymentDetailsError
} from '../utils/errors';
import { VIEW_TYPES, PAYMENT_METHODS } from '../constants';
import { MESSAGE_TYPES, TRACING_EVENTS } from '../../shared/constants';
import { notifyParent, trace } from '../utils/notifyParent';
import crossWindow from '../utils/crossWindow';

const Widget = (props) => {
	const ctx = useContext(WidgetConfigContext);
	const { getConfig } = ctx;
	const [ processingState, setProcessingState ] = useState();
	const [ error, setError ] = useState();
	const [ customer, setCustomer ] = useState();
	const [ initiated, setInitiated ] = useState(false);
	const isMobile = !useBreakpoint();
	const config = getConfig.result;

	const {
		type, showBillingDetails, showVatNumber,
		planId, billingDetails, legalInfo, businessAccount,
		subscriptionId, availablePaymentMethods, legalEntity,
		showCoupon, couponCode,
		showEditMode,
		redirectUrl,
		onError, onSuccess,
		widgetOffset,
	} = props;

	const notifyWidgetLoaded = useCallback(() => setTimeout(() => {
		notifyParent({ message: MESSAGE_TYPES.ON_LOAD });
	}, 1));

	useResize(({ width, height }) => {
		notifyParent({
			message: MESSAGE_TYPES.ON_RESIZE,
			payload: { width, height },
		});
	});

	useEffect(() => {
		// handle loading config errors
		if (!initiated) {
			if (getConfig.status === 'error') {
				const { error } = getConfig;
				setProcessingState({ error });
				setError(true);
				setInitiated(true);
				notifyWidgetLoaded();
				onError(error.serialize());
				error.propagate();
			}
		}

		if (!config || initiated) {
			return;
		}

		const customerStore = new Customer({
			sumupService: config.sumupService,
			billingService: config.billingService,
			epayService: config.epayService
		});

		const checkCustomerExists = () => {
			if (!customerStore.billingDetails) {
				throw new MissingCustomerError();
			}
		};

		const checkCustomerHasSubscription = () => {
			if (!customerStore.subscriptionId) {
				throw new MissingCustomerSubscriptionError();
			}
		};
		const handleInitiationError = (error) => {
			setProcessingState({ error });
			notifyWidgetLoaded();
		};

		if (type === VIEW_TYPES.purchase) {
			customerStore.setBillingDetails(billingDetails);
			customerStore.setCouponCode({ couponCode, planId });
			notifyWidgetLoaded();
		} else if (type === VIEW_TYPES.changeInfo) {
			customerStore.fetchCustomerDetails()
				.then(checkCustomerExists)
				.then(notifyWidgetLoaded)
				.catch(handleInitiationError);
		} else if (type === VIEW_TYPES.changePlan) {
			customerStore.fetchCustomerDetails()
				.then(checkCustomerExists)
				.then(checkCustomerHasSubscription)
				.then(notifyWidgetLoaded)
				.catch(handleInitiationError);
		}
		customerStore.setAvailablePaymentMethods(availablePaymentMethods);

		setCustomer(customerStore);
		setInitiated(true);
	});

	useEffect(() => {
		if (customer) {
			customer.setAvailablePaymentMethods(availablePaymentMethods);
		}
	}, [availablePaymentMethods]);

	if ((!config || !customer) && !error) {
		return null;
	}

	const do3DSChallenge = ({ url, method, payload }) => {
		const challengeData = { type: '3ds', url, method, payload };

		return new Promise((resolve, reject) => {
			notifyParent({ message: MESSAGE_TYPES.CARD_AUTHENTICATION_START, payload: { challengeData } });
			const removeListener = crossWindow.addMessageListener(MESSAGE_TYPES.CARD_AUTHENTICATION_FINISH, (msg) => {
				const { error, checkoutId } = msg;

				removeListener();
				error ? reject(error) : resolve(checkoutId);
			});
		});
	};

	const complete3DSChallenge = async (checkout) => {
		let checkoutId;

		if (checkout.next_step) {
			checkoutId = await do3DSChallenge(checkout.next_step);
			trace(TRACING_EVENTS.SCA_VERIFICATION_FINISHED, { flowType: 'two-step' });
		} else if (checkout.id) {
			checkoutId = checkout.id;
			trace(TRACING_EVENTS.SCA_VERIFICATION_FINISHED, { flowType: 'frictionless' });
		} else {
			new SumUpError('Unexpected response from SumUp api, missing checkoutId, next_steps payload')
				.addContext({ checkout })
				.propagate()
				.throw();
		}

		return checkoutId;
	};

	const purchaseSubscription = async (callback) => {
		const isCreditCard = customer.paymentMethodType === PAYMENT_METHODS.creditCard;

		try {
			const prepareProcessResult = await customer.prepareSubscriptionPurchase({
				redirectUrl,
			});
			let paymentMethodData;

			if (isCreditCard) {
				const checkoutId = await complete3DSChallenge(prepareProcessResult);

				paymentMethodData = await customer.verifyPaymentMethod({ checkoutId });
			} else {
				paymentMethodData = prepareProcessResult;
			}

			const subscriptionPurchaseDetails = await customer
				.purchaseSubscription({ planId, paymentMethodData });

			callback && callback(subscriptionPurchaseDetails);

			return {};
		} catch (error) {
			throw new PurchaseSubscriptionError(isCreditCard ? 'card' : 'direct_debit', error.message);
		}
	};

	const changePaymentDetails = async (callback) => {
		const isCreditCard = customer.paymentMethodType === PAYMENT_METHODS.creditCard;

		try {
			const prepareUpdateResult = await customer.preparePaymentMethodUpdate({
				redirectUrl,
			});
			let paymentMethodData;

			if (isCreditCard) {
				const checkoutId = await complete3DSChallenge(prepareUpdateResult);

				paymentMethodData = await customer.verifyPaymentMethod({ checkoutId });
			} else {
				paymentMethodData = prepareUpdateResult;
			}

			const paymentMethodUpdateResult = await customer.updatePaymentMethod(paymentMethodData);

			callback && callback(paymentMethodUpdateResult);

			callback && callback(paymentMethodUpdateResult);

			const { paymentMethodType } = paymentMethodUpdateResult;
			return paymentMethodType === PAYMENT_METHODS.creditCard
				? { creditCardUpdated: paymentMethodUpdateResult }
				: { directDebitUpdated: paymentMethodUpdateResult };
		} catch (error) {
			throw new ChangePaymentDetailsError(isCreditCard ? 'card' : 'direct_debit', error.message);
		}
	};

	const changeVatNumber = async (callback) => {
		let vatNumber;
		try {
			vatNumber = await customer.updateVatNumber();
		} catch (e) {
			throw new VatUpdateError();
		}

		callback && callback({ vatNumber });
		return {
			vatNumberUpdated: { vatNumber },
		};
	};

	const changeBillingDetails = async (callback) => {
		let billingDetails;
		try {
			billingDetails = await customer.updateBillingDetails();
		} catch (e) {
			throw new BillingDetailsUpdateError();
		}

		callback && callback({ billingDetails });
		return {
			billingDetailsUpdated: { billingDetails },
		};
	};

	const changePlan = async (callback) => {
		const paymentMethodDetails = await customer
			.updateSubscription({ planId, subscriptionId });

		callback && callback(paymentMethodDetails);

		return {};
	};

	const action = (handler, callback) => async () => {
		let error;
		let success;

		try {
			success = await handler(callback);
		} catch (e) {
			e.propagate && e.propagate();
			error = e;
		}

		setProcessingState({ error, success });

		return { error, success };
	};

	return (
		<Container data-testid="widget-container">
			{processingState && (
				<ProcessingStatus
					dismiss={() => setProcessingState(null)}
					{...processingState}
				/>
			)}
			<ErrorBoundary onError={(error) => setProcessingState({ error })}>
				{!error && <>
					{type === VIEW_TYPES.purchase && <PurchaseSubscription
						planId={planId}
						config={config}
						customer={customer}
						legalInfo={legalInfo}
						showBillingDetails={showBillingDetails}
						showVatNumber={showVatNumber}
						legalEntity={legalEntity}
						showCoupon={showCoupon}
						widgetOffset={widgetOffset}
						onSubmit={action(purchaseSubscription, onSuccess)}
						isMobile={isMobile}
						businessAccount={businessAccount}
					/>}
					{type === VIEW_TYPES.changeInfo && <ChangePaymentDetails
						customer={customer}
						config={config}
						showEditMode={showEditMode}
						showBillingDetails={showBillingDetails}
						showVatNumber={showVatNumber}
						legalEntity={legalEntity}
						onPaymentDetailsSubmit={action(changePaymentDetails, onSuccess)}
						onVatNumberSubmit={action(changeVatNumber, onSuccess)}
						onBillingDetailsSubmit={action(changeBillingDetails, onSuccess)}
						isMobile={isMobile}
						businessAccount={businessAccount}
					/>}
					{type === VIEW_TYPES.changePlan && <ChangePlan
						onSubmit={action(changePlan, onSuccess)}
						config={config}
						customer={customer}
						showBillingDetails={showBillingDetails}
						showVatNumber={showVatNumber}
						planId={planId}
						subscriptionId={subscriptionId}
						legalEntity={legalEntity}
						onPaymentDetailsSubmit={action(changePaymentDetails)}
						onVatNumberSubmit={action(changeVatNumber)}
						widgetOffset={widgetOffset}
						onBillingDetailsSubmit={action(changeBillingDetails, onSuccess)}
						isMobile={isMobile}
					/>}
				</>}
			</ErrorBoundary>
			<ScrollTop
				widgetOffset={widgetOffset}
				isMobile={isMobile}
			/>
		</Container>
	);
};

export default Widget;
