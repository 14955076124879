import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card } from '@sumup/circuit-ui';

import EditDetailsForms from './EditDetailsForms';
import styled from '@emotion/styled';

const FullWidthRow = styled.div`
	width: 100%;
	box-sizing: border-box;
`;

const ChangePaymentDetails = observer(({
	customer,
	config,
	showVatNumber,
	showEditMode,
	showBillingDetails,
	onPaymentDetailsSubmit,
	onVatNumberSubmit,
	onBillingDetailsSubmit,
	legalEntity,
	businessAccount,
}) => {
	const missingVatNumber = showVatNumber && !customer.billingDetails;

	if (!customer.paymentDetails || missingVatNumber) {
		return null;
	}
	return <FullWidthRow>
		<Card data-testid="edit-details">
			<EditDetailsForms
				variant="update"
				customer={customer}
				showEditMode={showEditMode}
				config={config}
				showBillingDetails={showBillingDetails}
				showVatNumber={showVatNumber}
				legalEntity={legalEntity}
				onPaymentDetailsSubmit={onPaymentDetailsSubmit}
				onVatNumberSubmit={onVatNumberSubmit}
				onBillingDetailsSubmit={onBillingDetailsSubmit}
				paymentMethods={customer.availablePaymentMethods}
				formSpan='8'
				businessAccount={businessAccount}
			/>
		</Card>
	</FullWidthRow>;
});

ChangePaymentDetails.propTypes = {
	onPaymentDetailsSubmit: PropTypes.func.isRequired,
	onVatNumberSubmit: PropTypes.func.isRequired,
	onBillingDetailsSubmit: PropTypes.func.isRequired,
	showBillingDetails: PropTypes.bool,
	showVatNumber: PropTypes.bool,
	legalEntity: PropTypes.string,
	customer: PropTypes.shape({
		setPaymentMethod: PropTypes.func.isRequired,
		setVatNumber: PropTypes.func.isRequired,
		billingDetails: PropTypes.object,
	}).isRequired,
	config: PropTypes.shape({
		sumupService: PropTypes.object.isRequired,
		billingService: PropTypes.object.isRequired,
	}).isRequired,
};

export default ChangePaymentDetails;
