import React from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import PriceSummaryLine from './PriceSummaryLine';
import { useTranslation } from 'react-i18next';
import Separator from '../separator';
import { formatDate } from '../../utils/date';
import { formatCurrency } from '../../utils/formatCurrency';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FullWidthShimmer } from '../shimmer';

import { Body } from '../body';
import { Headline, SubHeadline } from '../headline';

const StyledBody = styled(Body)`
	margin-bottom: ${({ theme }) => theme.spacings.mega};
`;

const PlanFrequency = styled(StyledBody)`
	margin-top: ${({ theme }) => theme.spacings.byte};
`;

const BottomText = styled(StyledBody)(({ theme }) => css`
	margin-top: ${theme.spacings.giga};
	color: var(--cui-fg-subtle);
`);

const PriceSummaryText = styled(BottomText)`
	margin-bottom: ${({ theme, isMobile }) => isMobile ? 0 : theme.spacings.mega};
`;

const priceLines = new Array(2).fill(null);
const summaryLines = new Array(4).fill(null);

// for the case when the coupon code is 100 percent and it needs to be shown the full sum for the next charge
const calculateFullAmount = ({ lines }) => {
	const net = lines.find(({ type }) => type === 'net') || { amount: 0 };
	const tax = lines.find(({ type }) => type === 'tax');
	if (tax) {
		const sum = net.amount + net.amount * (tax.rate / 100);
		return Math.round(sum * 100) / 100;
	}

	return net.amount;
};

const getTrialMessage = ({ lines, isInfiniteCoupon, isFullCouponEnabled }, billingCycle, nextChargeDate, priceTotal) => {
	let amount;
	if (isInfiniteCoupon) {
		amount = formatCurrency(0, priceTotal.currency);
	} else if (priceTotal.amount === 0) {
		amount = formatCurrency(calculateFullAmount({ lines }), priceTotal.currency);
	} else {
		amount = formatCurrency(priceTotal.amount, priceTotal.currency);
	}

	if (isFullCouponEnabled) {
		return (
			<Trans i18nKey="summary.subscription.renewal.notification_trial_and_full_coupon">Your card will be charged <strong>{{ amount }}</strong> on {{ nextChargeDate }}, and {{ billingCycle }} thereafter unless you cancel your membership.</Trans>
		);
	}

	return (
		<Trans i18nKey="summary.subscription.renewal.notification_trial">Your card will be charged <strong>{{ amount }}</strong> at the end of your free trial on {{ nextChargeDate }} and {{ billingCycle }} thereafter unless you cancel your membership.</Trans>
	);
};

const getMessage = (t, { lines, isInfiniteCoupon, isFullCouponEnabled }, billingCycle, nextChargeDate, priceTotal) => {
	let amount = formatCurrency(calculateFullAmount({ lines }), priceTotal.currency);
	if (isFullCouponEnabled && !isInfiniteCoupon) {
		return (
			<Trans i18nKey="summary.subscription.renewal.notification_trial_and_full_coupon">Your card will be charged <strong>{{ amount }}</strong> on {{ nextChargeDate }}, and {{ billingCycle }} thereafter unless you cancel your membership.</Trans>
		);
	}

	return t('summary.subscription.renewal.notification', { nextChargeDate });
};

const PriceSummary = observer(({ priceDetails, isMobile, couponCode }) => {
	const { t, i18n } = useTranslation();

	if (!priceDetails || !priceDetails.isLoaded) {
		return <PriceSummaryPlaceholder/>;
	}

	const { planName, planFrequency, lines, isTrialApplicable } = priceDetails;
	const priceTotal = lines.find(({ type }) => type === 'total') || {};

	const nextChargeDate = formatDate(i18n.language, priceDetails.nextChargeDate);

	const billingCycle = planFrequency === 'monthly' ? t('summary.subscription.renewal.billing_cycle.monthly') : t('summary.subscription.renewal.billing_cycle.yearly');

	const trialMessage = getTrialMessage(priceDetails, billingCycle, nextChargeDate, priceTotal);
	const message = getMessage(t, priceDetails, billingCycle, nextChargeDate, priceTotal);

	return (
		<div data-testid='price-summary'>
			<Headline as="h3" size='four'>{t('summary.title')}</Headline>
			<Separator />
			<SubHeadline as="h3" size='four'>{planName}</SubHeadline>
			<PlanFrequency>{
				planFrequency === 'monthly' ? t('summary.plan.frequency.month.billing_period') : t('summary.plan.frequency.year.billing_period')
			}</PlanFrequency>
			<Separator />
			{lines.map((line, i) => <PriceSummaryLine
				key={`price-summary-line-${i}`}
				planFrequency={planFrequency}
				line={line}
				couponCode={couponCode}
				isTrialApplicable={isTrialApplicable}
			/>)}
			<PriceSummaryText size={isMobile ? 'one' : 'two'} isMobile={isMobile}>
				{isTrialApplicable ? trialMessage : message}
			</PriceSummaryText>
		</div>
	);
});

function PriceSummaryPlaceholder() {
	const { t } = useTranslation();
	return (<div>
		<Headline as="h3" size='four'>
			<FullWidthShimmer>{t('summary.title')}</FullWidthShimmer>
		</Headline>
		<Separator />
		<SubHeadline as="h3" size='four'>
			<FullWidthShimmer>{t('summary.plan.product_name')}</FullWidthShimmer>
		</SubHeadline>
		<StyledBody>
			<FullWidthShimmer>{t('summary.plan.title')}</FullWidthShimmer>
		</StyledBody>
		<Separator />
		{priceLines.map((v, i) =>
			<FullWidthShimmer key={`price-summary-placeholder-line-${i}`}>
				{t('summary.price_summary')}
			</FullWidthShimmer>)}
		<Separator />
		<Headline as="h3" size='four'>
			<FullWidthShimmer>{t('summary.total')}</FullWidthShimmer>
		</Headline>
		<BottomText size="two">
			{summaryLines.map((v, i) => <FullWidthShimmer key={`price-summary-placeholder-renew-${i}`}>
				{t('summary.subscription.renewal.title')}
			</FullWidthShimmer>)}
		</BottomText>
	</div>);
}

export default PriceSummary;
