import '@sumup/design-tokens/light.css';
import '@sumup/circuit-ui/styles.css';

import React from 'react';
import './i18n';
import { ThemeProvider, Global } from '@emotion/react';
import { light } from '@sumup/design-tokens';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from './containers/Container';
import WidgetContainer from './containers/WidgetContainer';
import NotFound from './components/notFound/notFound';

const App = () => (
	<Router>
		<ThemeProvider theme={light}>
			<Global styles={{
				body: {
					backgroundColor: 'transparent',
					fontFamily: light.fontStack.default,
					margin: 0,
					padding: light.spacings.byte,
				},
				'input, select, svg': {
					boxSizing: 'border-box'
				}
			}}/>
			<Container data-testid="app-container">
				<Switch>
					<Route path="/" exact component={WidgetContainer} />
					<Route path="*" component={NotFound} />
				</Switch>
			</Container>
		</ThemeProvider>
	</Router>
);

export default App;
