import React from 'react';
import { useTranslation } from 'react-i18next';
import MaskInput from '../../input/maskInput';
import { spacing } from '@sumup/circuit-ui/legacy';

const SECURITY_CODE_MASK = [
	/\d/, /\d/, /\d/, /\d/
];

const propagateChange = handler => e => {
	const { value } = e.target;

	return handler(value.replace(/•/g, ''));
};

function SecurityCodeInput({ value, onChange, ...renderProps }) {
	const { t } = useTranslation();
	return (
		<MaskInput
			label={t('payment_method.credit_card.cvv.title')}
			type='password'
			mask={SECURITY_CODE_MASK}
			value={value}
			onChange={propagateChange(onChange)}
			autoComplete='cc-csc'
			css={spacing({ bottom: 'mega' })}
			{...renderProps}
		/>);
}

export default SecurityCodeInput;
