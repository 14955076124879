import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default (handler) => {
	const [observer] = useState(new ResizeObserver(entities => {
		const [{ target }] = entities;
		const { clientHeight: height, clientWidth: width } = target;

		handler({ width, height });
	}));

	useEffect(() => {
		observer.observe(document.body);
	}, []);
};
