import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PreviewTitle from '../previewTitle/previewTitle';
import { Body } from '../body';

const PreviewVatNumber = styled(Body)`
	${({ theme }) => `
		margin: ${theme.spacings.byte} 0;
	`}
`;

const VatIdPreview = ({ vatNumber }) => {
	const { t } = useTranslation();

	return <>
		<PreviewTitle size="two">{t('vat.id')}</PreviewTitle>
		<PreviewVatNumber>{vatNumber || t('vat.no_vat_id')}</PreviewVatNumber>
	</>;
};

export default VatIdPreview;
