import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { countryRegions } from '../../constants/country';
import CountrySelect from '../countrySelect/CountrySelect';
import RegionSelect from '../regionSelect';
import { InputRow, InputCol, Input } from '../input';
import {
	composeValidators,
	required,
	emailValidator,
	preventInjection,
} from '../../utils/form-validators';
import { spacing } from '@sumup/circuit-ui/legacy';

const BillingDetails = ({
	value: billingDetails,
	disableCountry,
	onChange,
}) => {
	const { t } = useTranslation();

	const handleBillingDetailsChange = (input) => (event) => {
		const { name: propertyName, value } = event.target;
		const changedBillingDetails = {
			...billingDetails,
			[propertyName]: value,
		};

		onChange(changedBillingDetails);

		if (input && input.onChange) {
			input.onChange(event);
		}
	};

	const handleRegionSettingsInput = (input) => (event) => {
		const { regionId, regionName } = event.region;
		onChange({
			...billingDetails,
			regionId,
			regionName,
		});

		if (input && input.onChange) {
			input.onChange(event);
		}
	};

	const countryHasRegions = billingDetails.country in countryRegions;
	const shouldShowRegionSettings = !disableCountry && countryHasRegions
		|| (countryHasRegions && (disableCountry && billingDetails.regionId));

	return (
		<div data-testid="billing-details">
			<InputRow >
				<InputCol >
					<Field
						name="firstName"
						validate={composeValidators(required(t('billing_details.first_name')), preventInjection)}
						defaultValue={billingDetails.firstName}
					>
						{({ meta, input }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.first_name')}
								value={billingDetails.firstName}
								onChange={handleBillingDetailsChange(input)}
								maxLength={100}
								autoComplete="given-name"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>

				<InputCol >
					<Field
						name="lastName"
						validate={composeValidators(required(t('billing_details.last_name')), preventInjection)}
						defaultValue={billingDetails.lastName}
					>
						{({ meta, input }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.last_name')}
								value={billingDetails.lastName}
								onChange={handleBillingDetailsChange(input)}
								maxLength={100}
								autoComplete="family-name"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<InputCol >
					<Field
						name="email"
						validate={composeValidators(
							required(t('billing_details.email')),
							emailValidator,
							preventInjection,
						)}
						defaultValue={billingDetails.email}
					>
						{({ meta, input }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.email')}
								value={billingDetails.email}
								onChange={handleBillingDetailsChange(input)}
								autoComplete="email"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<InputCol >
					<Field
						name="phone"
						defaultValue={billingDetails.phone}
						validate={preventInjection}
					>
						{({ input, meta }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.phone')}
								value={billingDetails.phone}
								onChange={handleBillingDetailsChange(input)}
								autoComplete="tel"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<InputCol >
					<Field
						name="company"
						validate={composeValidators(required(t('billing_details.company_name')), preventInjection)}
						defaultValue={billingDetails.company}
					>
						{({ meta, input }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.company_name')}
								value={billingDetails.company}
								onChange={handleBillingDetailsChange(input)}
								autoComplete="organization"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<InputCol >
					<Field
						name="addressLine1"
						validate={composeValidators(required(t('billing_details.address_line_1')), preventInjection)}
						defaultValue={billingDetails.addressLine1}
					>
						{({ meta, input }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.address_line_1')}
								value={billingDetails.addressLine1}
								onChange={handleBillingDetailsChange(input)}
								maxLength={100}
								autoComplete="address-line1"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<InputCol >
					<Field
						name="addressLine2"
						defaultValue={billingDetails.addressLine2}
						vadate={preventInjection}
					>
						{({ input, meta }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.address_line_2')}
								value={billingDetails.addressLine2}
								onChange={handleBillingDetailsChange(input)}
								maxLength={100}
								autoComplete="address-line2"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<InputCol >
					<Field
						name="zip"
						validate={composeValidators(required(t('billing_details.postcode')), preventInjection)}
						defaultValue={billingDetails.zip}
					>
						{({ meta, input }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.postcode')}
								value={billingDetails.zip}
								onChange={handleBillingDetailsChange(input)}
								autoComplete="postal-code"
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>

				<InputCol >
					<Field
						name="city"
						validate={required(t('billing_details.city'))}
						defaultValue={billingDetails.city}
					>
						{({ meta, input }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.city')}
								value={billingDetails.city}
								onChange={handleBillingDetailsChange(input)}
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<InputCol >
					<Field
						name="state"
						defaultValue={billingDetails.state}
						validate={preventInjection}
					>
						{({ input, meta }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.state')}
								value={billingDetails.state}
								onChange={handleBillingDetailsChange(input)}
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>

			<InputRow >
				<Field
					name="country"
					validate={required(t('billing_details.country.label'))}
					defaultValue={billingDetails.country}
				>
					{({ meta, input }) => (
						<InputCol >
							<CountrySelect
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.country.label')}
								value={billingDetails.country}
								onChange={handleBillingDetailsChange(input)}
								autoComplete="country"
								disabled={disableCountry}
							/>
						</InputCol>
					)}
				</Field>
			</InputRow>
			{shouldShowRegionSettings && <InputRow>
				<InputCol >
					<Field
						name="regionId"
						defaultValue={billingDetails.regionId}
					>
						{({ input }) => (
							<RegionSelect
								{...input}
								label={t('region.field.label')}
								value={billingDetails.regionId}
								country={billingDetails.country}
								onChange={handleRegionSettingsInput(input)}
								disabled={disableCountry}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>}
			<InputRow >
				<InputCol >
					<Field
						name="invoiceInfo"
						defaultValue={billingDetails.invoiceInfo}
						validate={preventInjection}
					>
						{({ input, meta }) => (
							<Input
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								{...input}
								label={t('billing_details.invoice_info')}
								value={billingDetails.invoiceInfo}
								onChange={handleBillingDetailsChange(input)}
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>
		</div>
	);
};

BillingDetails.propTypes = {
	value: PropTypes.shape({
		addressLine1: PropTypes.string.isRequired,
		addressLine2: PropTypes.string,
		city: PropTypes.string.isRequired,
		company: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		invoiceInfo: PropTypes.string,
		lastName: PropTypes.string.isRequired,
		phone: PropTypes.string,
		state: PropTypes.string,
		vatNumber: PropTypes.string,
		zip: PropTypes.string.isRequired,
		regionId: PropTypes.number,
		regionName: PropTypes.string,
	}).isRequired,
	disableCountry: PropTypes.bool,
	onChange: PropTypes.func,
};

BillingDetails.defaultProps = {
	value: {
		addressLine1: '',
		addressLine2: '',
		city: '',
		company: '',
		country: '',
		email: '',
		firstName: '',
		invoiceInfo: '',
		lastName: '',
		phone: '',
		state: '',
		vatNumber: '',
		zip: '',
		regionId: null,
		regionName: '',
	},
	disableCountry: false,
	onChange: () => {},
};

export default BillingDetails;
