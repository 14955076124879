import en from './en.json';
import de from './de-DE.json';
import es from './es-ES.json';
import fr from './fr-FR.json';
import nl from './nl-NL.json';
import it from './it-IT.json';
import fi from './fi-FI.json';
import enIE from './en-IE.json';
import frCH from './fr-CH.json';
import deCH from './de-CH.json';
import itCH from './it-CH.json';

export default  {
	en,
	'de-DE': de,
	'es-ES': es,
	'fr-FR': fr,
	'nl-NL': nl,
	'it-IT': it,
	'fi-FI': fi,
	'en-IE': enIE,
	'fr-CH': frCH,
	'de-CH': deCH,
	'it-CH': itCH,
};
