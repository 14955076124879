import React from 'react';
import { Container } from '../../containers/Container';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body } from '../body';

const Headline = styled.h1( (props) => ({
	fontSize: '7rem',
	fontWeight: 'bold',
	margin: `0 0 ${props.theme.spacings.exa}`,
}));

const StyledBody = styled(Body)`
	margin-bottom: ${({ theme }) => theme.spacings.mega};
`;

const NotFound = () => {
	const { t } = useTranslation();
	return (
		<Container
			textAlign="center"
			height='100vh'
			justifyContent='center'
			margin='0'>
			<Headline size="one">404</Headline>
			<StyledBody>{t('general.page.not_found')}</StyledBody>
		</Container>
	);
};

export default NotFound;
