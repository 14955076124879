import wrapper from './api';
import { trace } from '../utils/notifyParent';
import { ApiError, EPayError } from '../utils/errors';
import { TRACING_EVENTS } from '../../shared/constants';
import { parseJwt } from '../utils/parseToken';

function epayService({ token }) {
	const { customerReference = '', accountId = '' } = parseJwt(token) || {};

	const onError = (error) => {
		const serviceIsUnavailable =
			error instanceof EPayError &&
			(error.isNetworkError() ||
				error.isHTTPBadGateway() ||
				error.isHTTPServiceUnavailable());
		const details = error instanceof ApiError && error.details;
		if (serviceIsUnavailable) {
			trace(TRACING_EVENTS.SUMUP_REQUEST_BLOCKED, {
				details,
			});
		} else {
			trace(TRACING_EVENTS.SERVER_ERROR, {
				type: 'EPay service',
				reason: error.message,
				details,
			});
		}
	};

	const api = wrapper({
		request: {
			baseURL: '/epay/v1/cards',
			withCredentials: false,
			headers: {
				'Content-type': 'application/json',
			},
			errorHandled: true,
		},
		error: EPayError,
		onError,
	});

	async function validateCard({ couponCode }) {
		const url = '/validate';

		return api.post(url, {
			cardPan: couponCode,
			customerReference,
			accountId,
		});
	}

	return {
		validateCard,
	};
}

export default epayService;
