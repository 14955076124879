import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from './translations';

const resources = Object.entries(translations).reduce((acc, [key, value]) => {
	acc[key] = { translation: value };
	return acc;
}, {});

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		detection: {
			lookupQuerystring: 'languageCode',
			order: ['querystring'],
		},
		resources,
		fallbackLng: 'en',
		supportedLngs: ['en', 'de-DE', 'es-ES', 'fr-FR', 'it-IT', 'nl-NL', 'fi-FI', 'en-IE', 'fr-CH', 'de-CH', 'it-CH'],
		preload: true,
		interpolation: {
			escapeValue: false
		}
	});

// on localization will return same key that ordered to translate
i18n.debug = {
	enable: () => {
		i18n.__t = i18n.t;
		i18n.t = k => k;
	},
};

export default i18n;
