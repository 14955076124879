import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import i18n from '../../i18n';
import {
	InternalError,
	MissingCustomerError,
	MissingCustomerSubscriptionError,
	RuntimeError,
	VatUpdateError,
	BillingDetailsUpdateError,
	InitializationError,
	PaymentMethodVerificationError,
	ApiError,
	SumUpError,
	BillingError,
} from '../../utils/errors';
import useScrollTop from '../../utils/useScrollTop';
import { NotificationInline } from '../notification';

const NotificationWrapper = styled.div`
	width: 100%;
	margin-bottom: ${({ theme }) => theme.spacings.giga};
`;

const responseStatuses = {
	ALREADY_SUBSCRIBED_ERROR: {
		title: 'error.api.already_subscribed.title',
		message: 'error.api.already_subscribed.message',
	},
	INVALID_PAYLOAD_ERROR: {
		title: 'error.api.invalid_payload.title',
		message: 'error.api.invalid_payload.message',
	},
	INVALID_CARD_DATA_ERROR: {
		title: 'error.api.incorrect_card_data.title',
		message: 'error.api.incorrect_card_data.message',
	},
	CARD_PROCESSING_FAILED_ERROR: {
		title: 'error.api.card_declined.title',
		message: 'error.api.card_declined.message',
	},
	INTERNAL_SERVER_ERROR: {
		title: 'error.api.internal_error.title',
		message: 'error.api.internal_error.message',
	},
	VAT_UPDATE_ERROR: {
		title: 'error.api.vat_id_update.title',
		message: 'error.api.vat_id_update.message',
	},
	BILLING_DETAILS_UPDATE_ERROR: {
		title: 'error.api.billing_details_update.title',
		message: 'error.api.billing_details_update.message',
	},
	PAYMENT_METHOD_UPDATE_ERROR: {
		title: 'error.api.payment_method_update.title',
		message: 'error.api.payment_method_update.message',
	},
	GENERAL_SUCCESS: {
		title: 'notification.api.task_completed.title',
	},
	VAT_NUMBER_UPDATED: {
		title: 'notification.api.vat_id_update.title',
		message: 'notification.api.vat_id_update.message',
	},
	BILLING_DETAILS_UPDATED: {
		title: 'notification.api.billing_details_update.title',
		message: 'notification.api.billing_details_update.message',
	},
	PAYMENT_METHOD_UPDATED: {
		title: 'notification.api.payment_method_update.title',
		message: 'notification.api.payment_method_update.message',
	},
	DIRECT_DEBIT_UPDATED: {
		title: 'notification.api.direct_debit_updated.title',
		message: 'notification.api.direct_debit_updated.message',
	},
	UNAUTHORIZED: {
		title: 'error.api.unauthorized.title',
		message: 'error.api.unauthorized.message',
	},
	NOT_ENOUGH_PARAMS: {
		title: 'error.api.not_enough_params.title',
		message: 'error.api.not_enough_params.message',
	},
	MISSING_CUSTOMER: {
		title: 'error.api.missing_customer.title',
		message: 'error.api.missing_customer.message',
	},
	MISSING_SUBSCRIPTION: {
		title: 'error.api.missing_subscription.title',
		message: 'error.api.missing_subscription.message',
	},
	RUNTIME_ERROR: {
		title: 'error.internal.runtime_error.title',
		message: 'error.internal.runtime_error.message',
	},
	SERVER_IS_NOT_RESPONDING: {
		title: 'error.api.server_is_not_responding.title',
		message: 'error.api.server_is_not_responding.message',
	},
};

const handleInternalErrors = (error) => {
	let response;
	if (error instanceof VatUpdateError) {
		response = responseStatuses.VAT_UPDATE_ERROR;
	} else if (error instanceof BillingDetailsUpdateError) {
		response = responseStatuses.BILLING_DETAILS_UPDATE_ERROR;
	} else if (error instanceof InitializationError) {
		response = responseStatuses.NOT_ENOUGH_PARAMS;
	} else if (error instanceof PaymentMethodVerificationError) {
		response = responseStatuses.CARD_PROCESSING_FAILED_ERROR;
	} else if (error instanceof MissingCustomerError) {
		response = responseStatuses.MISSING_CUSTOMER;
	} else if (error instanceof MissingCustomerSubscriptionError) {
		response = responseStatuses.MISSING_SUBSCRIPTION;
	} else if (error instanceof RuntimeError) {
		response = responseStatuses.RUNTIME_ERROR;
	}

	return response;
};

const handleSuccess = (success) => {
	let response, params;

	const {
		creditCardUpdated,
		directDebitUpdated,
		vatNumberUpdated,
		billingDetailsUpdated,
	} = success;

	if (creditCardUpdated) {
		response = responseStatuses.PAYMENT_METHOD_UPDATED;
		params = {
			...creditCardUpdated,
			last4: creditCardUpdated.last4Digits,
		};
	} else if (directDebitUpdated) {
		response = responseStatuses.DIRECT_DEBIT_UPDATED;
		params = directDebitUpdated;
	} else if (vatNumberUpdated) {
		response = responseStatuses.VAT_NUMBER_UPDATED;
		params = vatNumberUpdated;
	} else if (billingDetailsUpdated) {
		response = responseStatuses.BILLING_DETAILS_UPDATED;
		params = billingDetailsUpdated;
	} else {
		response = responseStatuses.GENERAL_SUCCESS;
	}

	return { response, params };
};

const handleApiErrors = (error) => {
	if (error.isHTTPBadRequest()) {
		if (error.is(BillingError)) {
			if (error.customerHasSubscription) {
				return responseStatuses.ALREADY_SUBSCRIBED_ERROR;
			}
			if (error.hasInvalidPayload) {
				return responseStatuses.INVALID_PAYLOAD_ERROR;
			}

			return responseStatuses.CARD_PROCESSING_FAILED_ERROR;
		}
		if (error.is(SumUpError)) {
			return responseStatuses.INVALID_CARD_DATA_ERROR;
		}
		return responseStatuses.INTERNAL_SERVER_ERROR;
	}
	if (error.isHTTPForbidden()) {
		return responseStatuses.UNAUTHORIZED;
	}
	if (error.isHTTPConflict()) {
		return responseStatuses.CARD_PROCESSING_FAILED_ERROR;
	}
	if (error.isHTTPGatewayTimeout() || error.isHTTPServiceUnavailable()) {
		return responseStatuses.SERVER_IS_NOT_RESPONDING;
	}

	return responseStatuses.INTERNAL_SERVER_ERROR;
};

const getStatus = (error, success) => {
	let response, params;

	if (error instanceof InternalError) {
		response = handleInternalErrors(error);
	} else if (error instanceof ApiError) {
		response = handleApiErrors(error);
	} else if (success) {
		const res = handleSuccess(success);
		response = res.response;
		params = res.params;
	}

	if (!response) {
		return null;
	}

	const { title, message } = response;

	return {
		title: title && i18n.t(title, params),
		message: message && i18n.t(message, params),
	};
};

const ProcessingStatus = ({ error, success, dismiss }) => {
	const status = getStatus(error, success);
	const scrollTop = useScrollTop();

	useEffect(scrollTop, [error, success]);

	if (!status) {
		return null;
	}

	return (
		<NotificationWrapper data-testid="error-container">
			<NotificationInline
				variant={error ? 'danger' : 'success'}
				headline={status.title}
				body={status.message}
				onClose={dismiss}
				closeButtonLabel={i18n.t('general.button.close')}
			/>
		</NotificationWrapper>
	);
};

ProcessingStatus.propTypes = {
	error: PropTypes.object,
	success: PropTypes.object,
	dismiss: PropTypes.func,
};

export default ProcessingStatus;
