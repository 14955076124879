import React from 'react';
import { spacing } from '@sumup/circuit-ui/legacy';
import styled from '@emotion/styled';
import { Body } from '../body';

const PreviewTitle = styled(Body)`
	margin: 0;
	${({ theme }) => `
		margin-bottom: ${theme.spacings.bit};
		color: var(--cui-fg-subtle);
	`}
`;

const PreviewTitleWrapper = ({ spacingTop = false, children }) => {
	return (<>
		<PreviewTitle css={spacing({ bottom: 'kilo' })}>
			{children}
		</PreviewTitle>
	</>
	);
};

export default PreviewTitleWrapper;
