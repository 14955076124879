export var MESSAGE_TYPES = {
  // generic types, for identifying source of message
  IFRAME_MESSAGE: 'sumup-subscriptions-iframe-message',
  WIDGET_MESSAGE: 'sumup-subscriptions-widget-message',
  // specific types, for handling specific tasks
  CARD_AUTHENTICATION_START: 'card-authentication-start',
  CARD_AUTHENTICATION_FINISH: 'card-authentication-finish',
  ALIGN_OFFSET: 'widget-offset-alignment',
  ON_ERROR: 'widget-on-error',
  ON_SUCCESS: 'widget-on-success',
  ON_RESIZE: 'widget-on-resize',
  ON_LOAD: 'widget-on-load',
  ON_SCROLL_TOP: 'widget-on-scroll-top',
  LEGAL_INFO: 'legal-info',
  TRACE_EVENT: 'trace-event',
  BUSINESS_ACCOUNT: 'business-account'
};
export var SCA_MODAL_EVENTS = {
  USER_INTERRUPTED: 'sca-user-interrupted',
  MISSING_PARAMETER: 'sca-missing-parameter'
};
export var TRACING_EVENTS = {
  // Billing flow success
  SUBSCRIPTION_PURCHASED: 'subscription-purchased',
  PLAN_CHANGED: 'plan-changed',
  PAYMENT_METHOD_UPDATED: 'payment-method-updated',
  BILLING_DETAILS_UPDATED: 'billing-details-updated',
  PAYMENT_METHOD_VERIFICATION_COMPLETE: 'payment-method-verification-complete',
  // Billing flow error
  PURCHASE_FAILED: 'purchase-failed',
  PLAN_CHANGE_FAILED: 'plan-change-failed',
  BILLING_DETAILS_UPDATE_FAILED: 'billing-details-update-failed',
  PAYMENT_METHOD_UPDATE_FAILED: 'payment-method-update-failed',
  PAYMENT_METHOD_VERIFICATION_FAILED: 'payment-method-verification-failed',
  // HTTP communication errors
  SUMUP_REQUEST_BLOCKED: 'sumup-request-blocked',
  BILLING_SERVICE_UNAVAILABLE: 'billing-service-unavailable',
  SERVER_ERROR: 'server-error',
  // 3DS events
  SCA_VERIFICATION_STARTED: 'sca-verification-started',
  SCA_MODAL_OPENED: 'sca-modal-opened',
  SCA_MODAL_CLOSED: 'sca-modal-closed',
  SCA_VERIFICATION_FAILED: 'sca-verification-failed',
  SCA_VERIFICATION_FINISHED: 'sca-verification-finished',
  // Coupon events
  COUPON_VERIFIED: 'coupon-verified',
  COUPON_VERIFICATION_FAILED: 'coupon-verification-failed',
  // ePay events
  EPAY_CARD_VERIFIED: 'epay-card-verified',
  EPAY_CARD_VERIFICATION_FAILED: 'epay-card-verification-failed'
};