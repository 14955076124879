import React from 'react';
import { useTranslation } from 'react-i18next';
import MaskInput from '../../input/maskInput';
import { spacing } from '@sumup/circuit-ui/legacy';

// customers should use MM/YY for expiry
// but here 4 digits for year needed to make autocomplete work correctly
const EXPIRY_DATE_MASK = [
	/\d/, /\d/, '/',
	/\d/, /\d/, /\d/, /\d/,
];

const expireDateToTextValue = (expDate) => {
	const [month = '', _year = ''] = (expDate || []);
	const year = _year.slice(2);
	const chunks =  `${month}${year}`.match(/.{1,2}/g) || [];

	return chunks.join('/');
};

const propagateChange = handler => e => {
	const { value } = e.target;
	const [ month = '', _year = '' ] = value.split('/');
	const year = '20' + _year.slice(-2);

	handler && handler([month, year]);
};

const ExpiryDateInput = ({ value, onChange, ...renderProps }) => {
	const textValue = expireDateToTextValue(value);
	const { t } = useTranslation();

	return (
		<MaskInput
			label={t('payment_method.credit_card.expiry_date.title')}
			value={textValue}
			placeholder={t('payment_method.credit_card.expiry_date.field_placeholder')}
			mask={EXPIRY_DATE_MASK}
			onChange={propagateChange(onChange)}
			autoComplete='cc-exp'
			css={spacing({ bottom: 'mega' })}
			{...renderProps}
		/>);
};

export default ExpiryDateInput;
