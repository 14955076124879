import { useState, useEffect } from 'react';
import { DESKTOP_BREAKPOINT } from '../constants';

const useBreakpoint = (mq) => {
	const mediaQueryList = window.matchMedia(mq = DESKTOP_BREAKPOINT);

	const [match, setMatch] = useState(mediaQueryList.matches);

	useEffect(() => {
		const handler = () => setMatch(mediaQueryList.matches);
		mediaQueryList.addListener(handler);
		return () => mediaQueryList.removeListener(handler);
	}, []);

	return match;
};

export default useBreakpoint;
