const DEFAULT_PLACEHOLDER = 'AA000000000';

const PLACEHOLDERS = {
	AT: 'ATU00000000',
	BE: 'BE0000000000',
	BG: 'BG0000000000',
	CY: 'CY00000000L',
	CZ: 'CZ00000000',
	DE: 'DE00000000',
	DK: 'DK00000000',
	EE: 'EE000000000',
	EL: 'EL000000000',
	ES: 'ESX00000070',
	FI: 'FI00000000',
	FR: 'FRXX000000000',
	GB: 'GB00000000',
	HU: 'HU00000000',
	IE: 'IE0S00000L',
	IT: 'IT00000000000',
	LT: 'LT000000000',
	LU: 'LU00000000',
	LV: 'LV0000000000',
	NL: 'NL000000000',
	PL: 'PL0000000000',
	PT: 'PT000000000',
	RO: 'RO0000000000',
	SE: 'SE0000000000',
	SI: 'SI00000000',
	SK: 'SK00000000',
	HR: 'HR00000000000',
	CH: 'CHE00000000MWST',
	NO: 'NO00000000'
};

export const getVatIdPlaceholderForCountry = (countryCode) => {
	if (!countryCode) {
		return DEFAULT_PLACEHOLDER;
	}

	return PLACEHOLDERS[countryCode.toUpperCase()] || DEFAULT_PLACEHOLDER;
};
