import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../input';
import { spacing } from '@sumup/circuit-ui/legacy';

const propagateChange = handler => e => {
	const { value } = e.target;

	return handler && handler(value);
};

const CardholderNameInput = ({ value, onChange, ...renderProps }) => {
	const { t } = useTranslation();
	return (
		<Input
			label={t('payment_method.credit_card.cardholder.title')}
			value={value}
			maxLength="26"
			type="text"
			onChange={propagateChange(onChange)}
			autoComplete='cc-name'
			{...renderProps}
			css={spacing({ bottom: 'mega' })}
		/>
	);
};

export default CardholderNameInput;
