import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Card } from '@sumup/circuit-ui';

const OrderSummaryContainer = styled(Card)`
	flex: 1;
	align-items: self-start;
	position: relative;
	transition: top .5s;
	transition-delay: .2s;
`;

const OrderSummary = ({ isMobile, widgetOffset, ...other }) => {
	const { offset } = widgetOffset;


	const top = isMobile ? '0px' : `${offset}px`;

	return <OrderSummaryContainer css={{ top }} {...other}/>;
};

OrderSummary.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	widgetOffset: PropTypes.shape({
		offset: PropTypes.number.isRequired,
	})
};

export default OrderSummary;
