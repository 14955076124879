export const euCountries = [
	'AT', // Austria
	'IT', // Italy
	'BE', // Belgium
	'LV', // Latvia
	'BG', // Bulgaria
	'LT', // Lithuania
	'HR', // Croatia
	'LU', // Luxembourg
	'CY', // Cyprus
	'MT', // Malta
	'CZ', // Czech Republic
	'NL', // Netherlands
	'DK', // Denmark
	'PL', // Poland
	'EE', // Estonia
	'PT', // Portugal
	'FI', // Finland
	'RO', // Romania
	'FR', // France
	'SK', // Slovakia
	'DE', // Germany
	'SI', // Slovenia
	'GR', // Greece
	'ES', // Spain
	'HU', // Hungary
	'SE', // Sweden
	'IE', // Ireland
];

export const allCountries = Array.from(new Set([
	...euCountries,
	'RU', // Russia
	'BR', // Brazil
	'CH', // Switzerland
	'GB', // United Kingdom
	'US', // United States
	'CL', // Chile
	'NO', // Norway
	'CO', // Colombia
	'AD', // Andorra
	'AF', // Afghanistan
	'AM', // Armenia
	'AO', // Angola
	'AR', // Argentina
	'AU', // Australia
	'AZ', // Azerbaijan
	'BA', // Bosnia Herzegovina
	'BD', // Bangladesh
	'BF', // Burkina Faso
	'CA', // Canada
	'CI', // Ivory Coast
	'CM', // Cameroon
	'CN', // China / Hong Kong
	'DZ', // Algeria
	'EC', // Ecuador
	'EG', // Egypt
	'GE', // Georgia
	'GH', // Ghana
	'IR', // Iran
	'IS', // Iceland
	'JP', // Japan
	'KE', // Kenya
	'KR', // Korea
	'KW', // Kuwait
	'KZ', // Kazakhstan
	'LB', // Lebanon
	'LI', // Liechtenstein
	'MA', // Morocco
	'MD', // Moldova
	'ME', // Montenegro
	'MK', // Macedonia
	'ML', // Mali
	'MW', // Malawi
	'MX', // Mexico
	'MY', // Malaysia
	'NG', // Nigeria
	'NZ', // New Zealand
	'PE', // Peru
	'PH', // Philippines
	'RS', // Serbia
	'SG', // Singapore
	'TH', // Thailand
	'TR', // Turkey
	'TW', // Taiwan (Republic China)
	'UA', // Ukraine
	'VE', // Venezuela
	'XK', // Kosovo
	'ZA', // South Afric
]));

export const countryRegions = {
	'ES': [
		{
			regionId: 60,
			regionName: 'Las Palmas',
		},
		{
			regionId: 63,
			regionName: 'Santa Cruz de Tenerife',
		},
		{
			regionId: 76,
			regionName: 'Ceuta',
		},
		{
			regionId: 77,
			regionName: 'Melilla',
		}
	],
	'PT': [
		{
			regionId: 99,
			regionName: 'Açores',
		},
		{
			regionId: 111,
			regionName: 'Madeira',
		},
	],
};
