import React from 'react';
import styled from '@emotion/styled';
import {
	ApplePay, AmericanExpress, Discover, Maestro, Mastercard,
	UnionPay, Visa, VisaElectron,
} from '@sumup/icons';
import { useTranslation } from 'react-i18next';
import PreviewTitle from '../../previewTitle/previewTitle';
import { Body } from '../../body';

const IconWrapper = styled.span`
	display: inline-block;
	vertical-align: middle;
	${({ theme }) => `
		min-width: ${theme.iconSizes.giga};
		padding: 0 calc(${theme.spacings.bit} / 2);
		height: ${theme.iconSizes.mega};
		border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
		border-radius: ${theme.borderRadius.mega};
		text-align: center;
		svg {
			max-height: calc(${theme.iconSizes.mega} - 2px);
		}
	`}
`;

const PreviewCardNumber = styled(Body)`
	${({ theme }) => `
		display: flex;
		gap: ${theme.spacings.byte};
		margin: ${theme.spacings.bit} 0 0;
	`}
`;

const CardIcon = ({ type }) => {
	let icon;
	switch (type.toUpperCase()) {
		case 'AMEX':
			icon = <AmericanExpress />;
			break;
		case 'APPLE_PAY':
			icon = <ApplePay/>;
			break;
		case 'DISCOVER':
			icon = <Discover/>;
			break;
		case 'MAESTRO':
			icon = <Maestro/>;
			break;
		case 'MC':
		case 'MASTERCARD':
			icon = <Mastercard/>;
			break;
		case 'UNION_PAY':
			icon = <UnionPay/>;
			break;
		case 'VISA':
			icon = <Visa/>;
			break;
		case 'VISA_ELECTRON':
			icon = <VisaElectron/>;
			break;
		default:
			return null;
	}

	return <IconWrapper>{icon}</IconWrapper>;
};

const CardNumberPreview = ({ last4, type }) => {
	const { t } = useTranslation();
	return <div data-testid='credit-card-preview'>
		<PreviewTitle size="kilo">{t('payment_method.credit_card.card_number.title')}</PreviewTitle>
		<PreviewCardNumber data-testid='credit-card-last4'>
			****{last4}
			<CardIcon type={type}/>
		</PreviewCardNumber>
	</div>;
};

export default CardNumberPreview;
