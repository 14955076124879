import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import styled from '@emotion/styled';
import { spacing } from '@sumup/circuit-ui/legacy';
import { Button } from '@sumup/circuit-ui';
import { Edit } from '@sumup/icons';

const VARIANTS = {
	edit: 'edit',
	update: 'update'
};

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	${({ theme }) => `
		gap: ${theme.spacings.byte};
	`}
`;

const PreviewContainer = styled.div`
	position: relative;
	${({ theme }) => `
		min-height: ${theme.spacings.peta};
		padding: ${theme.spacings.byte};
	`} 
`;

const EditButton = styled(Button)`
	padding-right: 0;
	position: absolute;
	right:0;
	z-index: 2;
	${({ theme }) => `
		top: ${theme.spacings.byte};
		padding-right: ${theme.spacings.byte};
	`}
`;

const EditIcon = props => <Edit size="16" {...props} />;

const EditContainer = ({
	customer,
	preview,
	showEditMode,
	initialValues,
	onSuccess,
	onCancel,
	variant = VARIANTS.edit,
	testId = 'form',
	children,
	readonly = false,
}) => {
	const { t } = useTranslation();
	const [edited, setEdited] = useState(false);
	const [backupValues, setBackupValues] = useState(null);

	useEffect(() => {
		if (showEditMode) {
			startEditing();
		}
	}, [showEditMode]);

	const onSubmit = async () => {
		const actionResult = await onSuccess();
		const { error } = actionResult || {};
		if (!error) {
			setEdited(false);
			customer.setIsEdited(false);
		}
	};

	const startEditing = async () => {
		setBackupValues(initialValues);
		customer.setIsEdited(true);
		setEdited(true);
	};

	const cancelEditing = async () => {
		setEdited(false);
		setBackupValues(null);
		customer.setIsEdited(false);
		onCancel && (await onCancel(backupValues || {}));
	};

	const submitBtnPlaceholder = t(
		variant === VARIANTS.edit ? 'general.button.edit' : 'general.button.update'
	);

	if (!edited) {
		return (
			<PreviewContainer css={spacing({ bottom: 'kilo' })}>
				{preview}
				<EditButton
					variant="tertiary"
					onClick={startEditing}
					icon={EditIcon}
					data-testid="edit-form-open-button"
					disabled={readonly}
				>
					{t(submitBtnPlaceholder)}
				</EditButton>
			</PreviewContainer>
		);
	}

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={backupValues}
		>
			{({
				submitting,
				validating,
				valid,
				handleSubmit,
				dirty,
			}) => (
				<form onSubmit={handleSubmit} data-testid={testId}>
					{children}
					<ButtonContainer css={spacing({ bottom: 'kilo' })}>
						<Button
							data-testid="edit-form-submit-button"
							variant="primary"
							type="submit"
							disabled={!valid || validating}
							isLoading={submitting || validating}
							loadingLabel="loading"
						>
							{t(submitBtnPlaceholder)}
						</Button>
						<Button
							data-testid="edit-form-cancel-button"
							variant="tertiary"
							type="button"
							onClick={cancelEditing}
						>
							{t('general.button.cancel')}
						</Button>
					</ButtonContainer>
				</form>
			)}
		</Form>
	);
};

EditContainer.propTypes = {
	preview: PropTypes.node.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onEditStart: PropTypes.func,
	onCancel: PropTypes.func,
	customer: PropTypes.shape({
		setIsEdited: PropTypes.func,
	}),
	initialValues: PropTypes.any,
	variant: PropTypes.oneOf(Object.values(VARIANTS)),
	testId: PropTypes.string,
	readonly: PropTypes.bool,
};

export default EditContainer;
