import wrapper from './api';
import { ApiError, ViesError } from '../utils/errors';
import { trace } from '../utils/notifyParent';
import { TRACING_EVENTS } from '../../shared/constants';

const onError = (error => {
	const details = error instanceof ApiError && error.details;
	trace(TRACING_EVENTS.SERVER_ERROR, {
		type: 'Debitoor vies service',
		reason: error.message,
		details,
	});
});

const api = wrapper({
	error: ViesError,
	onError,
});

const validateVAT = (countryCode, vatNumber) => api.post('/api/validate', {
	countryCode,
	vatNumber,
});

export default {
	validateVAT,
};
