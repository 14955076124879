/*
 * Field counts as valid if validator will returns `Falsy` value
 * https://developer.mozilla.org/en-US/docs/Glossary/Falsy
 */

import viesService from '../services/viesService';
import creditCardValidator from 'card-validator';
import IBAN from 'iban';
import i18n from '../i18n';
import moment from 'moment';
import { euCountries } from '../constants/country';

const EXPIRY_DATE_EXP = /(0[1-9]|1[0-2])\/(20\d{2})/;
const VAT_SHAPE_EXP = /^[A-Z]{2}.{4,15}/;
const HTML_TAGS_EXP = /(<([^>]+)>)/gi;

const FIELD_REQUIRED = 'error.validation.required';
const FIELD_MIN_LENGTH = 'error.validation.min_length';
const INVALID_EXPIRY_DATE_FORMAT = 'error.validation.expiry_date_format';
const INVALID_EXPIRY_DATE_DATE = 'error.validation.expiry_date_date';
const INVALID_CARD_NUMBER = 'error.validation.card_number';
const INVALID_VAT_NUMBER = 'error.validation.vat_id';
const INVALID_IBAN = 'error.validation.iban';
const INVALID_EMAIL = 'error.validation.email';
const INVALID_INPUT = 'error.validation.invalid_input';

const EU_COUNTRIES = new Set(euCountries);

// this limitations come from sumup backend
// checkout with DD will fail if country is not from this list
const DIRECT_DEBIT_COUNTRIES = [
	'AT', 'BE', 'CY', 'DE', 'EE', 'ES',
	'FI', 'FR', 'GR', 'IE', 'IT', 'LT',
	'LU', 'LV', 'MT', 'NL', 'PT', 'SI', 'SK'
];

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

export const required = fieldName => value => {
	if (!value) {
		return capitalize(i18n.t(FIELD_REQUIRED, { fieldName }));
	}
};

export const minLength = minLength => value => {
	if (value.length && value.length < minLength) {
		return capitalize(i18n.t(FIELD_MIN_LENGTH, { minLength }));
	}
};

export const expiryDateValidator = value => {
	const match = (value || '').match(EXPIRY_DATE_EXP);
	if (!match) {
		return capitalize(i18n.t(INVALID_EXPIRY_DATE_FORMAT));
	}
	const month = match[1];
	const year = match[2];
	const today = moment().startOf('month');
	const isFutureDate = moment(today)
		.year(year)
		.month(month)
		.isSameOrAfter(today);

	if (!isFutureDate) {
		return capitalize(i18n.t(INVALID_EXPIRY_DATE_DATE));
	}
};

export const cardNumberValidator = value => {
	const validation = creditCardValidator.number(value);

	if (!validation.isPotentiallyValid) {
		return capitalize(i18n.t(INVALID_CARD_NUMBER));
	}
};

export const vatShapeValidator = async value => {
	if (value && !VAT_SHAPE_EXP.test(value)) {
		return capitalize(i18n.t(INVALID_VAT_NUMBER));
	}
};

export const emailValidator = value => {
	const isValidEmail = value && value.includes('@');

	if (!isValidEmail) {
		return capitalize(i18n.t(INVALID_EMAIL));
	}
};

export const composeValidators = (...validators) => async inputValue => {
	const iterator = validators[Symbol.iterator]();

	let validation = iterator.next();
	let error = null;

	while (!error && !validation.done) {
		error = await validation.value(inputValue);
		validation = iterator.next();
	}

	return error;
};

export const vatValidatorFactory = (countryCode, debounce = 300) => {
	let timer;
	let cb;

	const isEUCountry = EU_COUNTRIES.has(countryCode);

	return (vatNumber) => {
		if (!vatNumber || !isEUCountry) {
			return;
		}

		cb && cb();
		timer && clearTimeout(timer);

		return new Promise((resolve) => {
			cb = resolve;
			timer = setTimeout(async () => {
				let result;
				try {
					result = await viesService.validateVAT(countryCode, vatNumber);
				} catch (e) {
					e.propagate();
				}
				let message;

				if (!result || !result.valid) {
					message = capitalize(i18n.t(INVALID_VAT_NUMBER));
				}

				resolve(message);
			}, debounce);
		});
	};
};

export const preventInjection = (value = '') => {
	const hasHTMLtags = HTML_TAGS_EXP.test(value || '');

	if (hasHTMLtags) {
		return capitalize(i18n.t(INVALID_INPUT));
	}
};

export const ibanValidator = value => {
	const isValidIBAN = IBAN.isValid(value);

	const countryCode = `${value}`.substring(0, 2);
	const isValidCountry = DIRECT_DEBIT_COUNTRIES.includes(countryCode);

	if (!isValidIBAN || !isValidCountry) {
		return capitalize(i18n.t(INVALID_IBAN));
	}
};

export const memo = fn => {
	let lastArg;
	let lastResult;
	return arg => {
		if (arg !== lastArg) {
			lastArg = arg;
			lastResult = fn(arg);
		}
		return lastResult;
	};
};
