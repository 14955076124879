import React, { useEffect, useCallback, useState } from 'react';
import { spacing, Tooltip } from '@sumup/circuit-ui/legacy';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { InputRow, InputCol, Input } from '../input';
import { Field } from 'react-final-form';
import { getVatIdPlaceholderForCountry } from './vatPlaceholder';
import { composeValidators, vatShapeValidator, vatValidatorFactory, memo } from '../../utils/form-validators';
import { TooltipIcon } from '../../helpers/Styled';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const LabelContainer = styled.div`
	display: flex;
	align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
	min-width: 250px;
`;

const TooltipContainer = styled.div(({ theme }) => css`
	height: ${theme.iconSizes.kilo};
	position: relative;
	margin-left: ${theme.spacings.bit};
`);

const VatInputLabel = () => {
	const { t } = useTranslation();
	const [tooltipVisible, setTooltipVisible] = useState(false);

	return (
		<LabelContainer>
			{t('vat.field.label')}
			<TooltipContainer data-testid="vat-number-tooltip">
				{ tooltipVisible && (
					<StyledTooltip position="top" align="right">
						{t('vat.field.tooltip')}
					</StyledTooltip>)
				}
				<TooltipIcon
					size="16"
					onMouseEnter={() => setTooltipVisible(true)}
					onMouseLeave={() => setTooltipVisible(false)}
				/>
			</TooltipContainer>
		</LabelContainer>
	);
};
const VatNumber = ({ value, onChange, country, ...renderProps }) => {
	const validate = useCallback(composeValidators(
		vatShapeValidator,
		memo(vatValidatorFactory(country, 400)),
	), [country]);

	const form = useForm();

	// do not include `form` in the dependecy array
	// it is not referential equality safe and will trigger an infinite loop
	useEffect(() => {
		if (form && form.mutators.setFieldTouched) {
			form.mutators.setFieldTouched('vatNumber', true);
		}
	}, [country]);

	return (
		<div data-testid="vat-number">
			<InputRow >
				<InputCol >
					<Field
						name="vatNumber"
						initialValue={value}
						validate={validate}
					>
						{({ meta, input }) => {
							if (!meta.validating && meta.modified) {
								onChange(meta.valid ? input.value : '');
							}
							return (<Input
								label={<VatInputLabel/>}
								id="billing-details-vat-number"
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								placeholder={getVatIdPlaceholderForCountry(country)}
								css={spacing({ bottom: 'mega' })}
								{...input}
								{...renderProps}
							/>);
						}}
					</Field>
				</InputCol>
			</InputRow>
		</div>
	);
};

export default VatNumber;
