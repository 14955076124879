import z from 'zod';
import { VIEW_TYPES } from '../constants';
import { InitializationError } from './errors';

const REQUIRED_DD_PROPS = ['company', 'legalEntity'];

// TODO tech debt builds up
// needed more transparent mechanism for parameters validation
// - handle specific widget view type parameters
// - handle specific mechanism parameters
// - do it with more general type designs

export function validateParams(params) {
	const {
		type,
		token,
	} = params;

	if (!Object.values(VIEW_TYPES).includes(type)) {
		return {
			error: new InitializationError('Wrong type passed', { type }),
			valid: false
		};
	}

	if (!token) {
		return {
			error: new InitializationError('Required parameter "token" is missing'),
			valid: false
		};
	}

	let requiredParams = REQUIRED_PARAMS[type];

	// purchase subscription specific validation
	if (type === VIEW_TYPES.purchase) {
		const { showDirectDebit, showBillingDetails } = params;
		const missingDirectDebitProps = REQUIRED_DD_PROPS.filter(name => !params[name]);

		if (showDirectDebit && missingDirectDebitProps.length > 0) {
			return {
				error: new InitializationError(
					'Missing Direct Debit additional parameters',
					{ missingDirectDebitProps },
				),
				valid: false
			};
		}

		if (showBillingDetails) {
			// if `showBillingDetails` is true, apart from planId and country,
			// the user will fill out the remaining required fields
			const nonRequiredParamsWhenVisible = [
				'firstName',
				'lastName',
				'email',
				'company',
				'city',
				'zip',
				'addressLine1'
			];
			requiredParams = requiredParams.filter(
				(param) => !nonRequiredParamsWhenVisible.includes(param)
			);
		}
	}

	for (let requiredParam of requiredParams) {
		if (!params[requiredParam]) {
			return {
				error: new InitializationError(
					'Missing required parameter',
					{ requiredParam }
				),
				valid: false
			};
		}
	}

	return { valid: true };
}

export const businessAccountValidationSchema = z.object({
	businessAccountDirectDebitsEnabled: z.boolean(),
	businessAccountCompanyName: z.string().nonempty(),
	businessAccountIban: z.string().min(5).max(34).regex(/^[A-Z0-9\s]+$/),
});

const REQUIRED_PARAMS = {
	[VIEW_TYPES.changeInfo]: [],
	[VIEW_TYPES.changePlan]: ['subscriptionId', 'planId'],
	[VIEW_TYPES.purchase]: [
		'planId',
		'firstName',
		'lastName',
		'email',
		'company',
		'country',
		'city',
		'zip',
		'addressLine1'
	],
};
