
import styled from '@emotion/styled';

export const Container = styled.div(props => ({
	display: props.display || 'flex',
	flexDirection: props.flexDirection || 'column',
	alignItems: props.alignItems || 'center',
	justifyContent: props.justifyContent || 'center',
	width: props.width || '100%',
	minWidth: props.minWidth || 'inherit',
	maxWidth: props.maxWidth || 'none',
	height: props.height || 'inherit',
	textAlign: props.textAlign || 'left',
	boxSizing: 'border-box',
	margin: '0 auto',

	[props.theme.mq.kilo]: {
		margin: props.margin || '1rem auto 3rem',
	},
}));
