import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PreviewTitle from '../previewTitle/previewTitle';
import { Body } from '../body';

const PreviewLine = styled(Body)`
	margin: 0 0;
`;

const BillingDetailsPreview = ({ billingDetails }) => {
	const { t } = useTranslation();
	const translatedCountry = t(`country.${billingDetails.country}`);

	const customerSection = (<>
		<PreviewTitle size="two">{t('billing_details.customer')}</PreviewTitle>
		<PreviewLine>{billingDetails.firstName} {billingDetails.lastName}</PreviewLine>
		<PreviewLine>{billingDetails.email}</PreviewLine>
		<PreviewLine>{billingDetails.phone}</PreviewLine>
		<PreviewLine>{billingDetails.company}</PreviewLine>
	</>);

	const countryInformation = [billingDetails.state, billingDetails.regionName, translatedCountry]
		.filter(el => el)
		.join(',');

	const addressSection = (<>
		<PreviewTitle size="kilo" spacingTop>{t('billing_details.address')}</PreviewTitle>
		<PreviewLine>{billingDetails.addressLine1}</PreviewLine>
		<PreviewLine>{billingDetails.addressLine2}</PreviewLine>
		<PreviewLine>
			{billingDetails.zip}, {billingDetails.city}
		</PreviewLine>
		<PreviewLine>{countryInformation}</PreviewLine>
	</>);

	const invoiceInfoSection = billingDetails.invoiceInfo && (<>
		<PreviewTitle size="kilo" spacingTop>{t('billing_details.invoice_info')}</PreviewTitle>
		<PreviewLine>{billingDetails.invoiceInfo}</PreviewLine>
	</>);

	return <>
		{customerSection}
		{addressSection}
		{invoiceInfoSection}
	</>;
};

export default BillingDetailsPreview;
