import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ChevronUp } from '@sumup/icons';
import useScrollTop from '../../utils/useScrollTop';

const Container = styled('div')`
	${({ theme }) => `
		padding: ${theme.spacings.mega};
		border-radius: 50%;
		background-color: var(--cui-bg-highlight);
		color: var(--cui-fg-placeholder);
		opacity: 1;
		position: fixed;
		right: 15px;
		transition: top .5s ease-out .2s, opacity .5s ease-in .5s;
		opacity: .4;
		cursor: pointer;
		
		&.hidden {
			bottom: -100px;
			opacity: 0;
			z-index: -1;
		}
		
		&:hover {
			opacity: 1;
			z-index: 1;
			transition: opacity .2s ease-out 0s;
		}
	`}`
;

const ScrollTop = ({ widgetOffset, isMobile }) => {
	const { offset, innerHeight, fullHeight } = widgetOffset;
	const scrollTop = useScrollTop();
	const shouldDisplayOffset = !isMobile && offset > innerHeight / 2;
	const correctedOffset = Math.min(offset + innerHeight, fullHeight);
	const top = `${correctedOffset - 75}px`;

	return (
		<Container
			css={{ top }}
			className={`${shouldDisplayOffset ? '' : 'hidden'}`}
			onClick={scrollTop}
		>
			<ChevronUp size="16" />
		</Container>
	);
};

ScrollTop.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	widgetOffset: PropTypes.shape({
		offset: PropTypes.number.isRequired,
		innerHeight: PropTypes.number.isRequired,
	}),
};

export default ScrollTop;
