import axios from 'axios';
import { inject } from './api.util';
import { ApiError } from '../utils/errors';

const wrapper = ({ error: errorMeta, onError, request: requestConfig } = {}) => {
	const instance = axios.create(requestConfig);
	if (['test', 'development'].includes(process.env.NODE_ENV)) {
		inject(instance);
	}
	instance.interceptors.response.use((response) => {
		return response.data;
	}, err => {
		let interceptedError;

		if (errorMeta instanceof Function) { // specific error constructor
			const Err = errorMeta;
			const {
				message,
				config: { url, method },
				response: { status, data } = {},
			} = err;

			interceptedError = new Err(message, {
				url,
				method,
				status,
				data,
			});
		} else {
			interceptedError = { ...errorMeta, ...err };
		}

		onError && onError(interceptedError);

		return Promise.reject(interceptedError);
	});

	return instance;
};

export const request = wrapper({
	request: {
		baseURL: '/',
		withCredentials: false,
		errorHandled: true,
	},
	error: ApiError,
});

export default wrapper;
