import React, { useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Separator from '../separator';
import { TooltipIcon } from '../../helpers/Styled';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Col, Row, Tooltip } from '@sumup/circuit-ui/legacy';
import { css } from '@emotion/react';
import { formatCurrency } from '../../utils/formatCurrency';

const StyledTooltipSmall = styled(Tooltip)`
	width: 165px;
`;

const TooltipContainerSmall = styled.span(({ theme }) => css`
	height: ${theme.iconSizes.kilo};
	position: absolute;
	margin-left: ${theme.spacings.bit};
	width: 20px;
`);

const PriceSummaryRow = styled(Row)`
	display: flex;
	flex-flow: row nowrap;
	align-items: start;
	margin-bottom: ${props => props.theme.spacings.bit};
`;

const PriceSummaryNameCol = styled(Col)`
	flex: 1;
`;

const PriceSummaryAmountCol = styled(Col)`
	flex: 1;
	white-space: nowrap;
	text-align: right;
	width: auto;
`;

const TooltipContainer = ({ text }) => {
	const [tooltipVisible, setTooltipVisible] = useState(false);
	return <TooltipContainerSmall data-testid="refund-tooltip">
		{ tooltipVisible && (
			<StyledTooltipSmall position="top" align="right">
				{text}
			</StyledTooltipSmall>)
		}
		<TooltipIcon
			onMouseEnter={() => setTooltipVisible(true)}
			onMouseLeave={() => setTooltipVisible(false)}
		/>
	</TooltipContainerSmall>;
};

const proRataTooltip = (days) => <TooltipContainer text={i18n.t('summary.subscription.refund.tooltip', { days })} />;
const discountTooltip = (discountRate, couponCode) => <TooltipContainer text={i18n.t('coupon_code.tooltip', { discountRate, couponCode })}  />;

const PriceSummaryLine = ({ line, planFrequency, couponCode, isTrialApplicable }) => {
	const { t } = useTranslation();
	const shouldSkipTaxLine = line.type === 'tax' && line.taxType === 'none';

	if (shouldSkipTaxLine) {
		return null;
	}

	const getText = () => ({
		net: isTrialApplicable ? t('summary.trial_period') : `1 ${planFrequency === 'monthly' ? t('summary.plan.frequency.month.title') : t('summary.plan.frequency.year.title')}`,
		tax: `${t('vat.summary')} ${line.rate}%`,
		refund: t('summary.prorata'),
		subtotal: t('summary.subtotal'),
		total: t('summary.total'),
		discount: t('summary.discount')
	});

	const isLineTypeTotal = line.type === 'total';
	const amount = isTrialApplicable && isLineTypeTotal ? 0 : line.amount;
	const amountWithCurrency = formatCurrency(amount, line.currency);
	const renderSeparatorBeforeLine = isLineTypeTotal || line.type === 'subtotal';
	const fontWeight = isLineTypeTotal || line.type === 'subtotal' ? 'bold' : 'normal';
	const fontSize = isLineTypeTotal && line.refundDaysAmount ? '1.3em' : '1em';
	const textDecoration = isTrialApplicable && !isLineTypeTotal ? 'line-through' : 'none';
	const text = getText()[line.type];

	return (
		<>
			{renderSeparatorBeforeLine && <Separator />}
			<PriceSummaryRow style={{ fontWeight }} data-testid='price-summary-line'>
				<PriceSummaryNameCol>{text}
					{line.type === 'refund' && proRataTooltip(line.refundDaysAmount)}
					{line.type === 'discount' && discountTooltip(line.rate, couponCode)}
				</PriceSummaryNameCol>
				<PriceSummaryAmountCol style={{ fontSize, textDecoration }}>{amountWithCurrency}</PriceSummaryAmountCol>
			</PriceSummaryRow>
		</>
	);
};

PriceSummaryLine.propTypes = {
	planFrequency: PropTypes.string.isRequired,
	line: PropTypes.shape({
		type: PropTypes.string.isRequired,
		amount: PropTypes.number.isRequired,
		currency: PropTypes.string.isRequired,
		rate: PropTypes.number,
		refundDaysAmount: PropTypes.number
	}).isRequired,
	isTrialApplicable: PropTypes.bool.isRequired,
};

export default PriceSummaryLine;
