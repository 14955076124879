
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { allCountries } from '../../constants/country';

import * as CountrySelectService from './CountrySelectService';
import { Select } from '../select';

const StyledSelect = styled(Select)`
	margin-bottom: ${({ theme }) => theme.spacings.mega};
`;

/**
 * A simple select component for our supported countries. Since this is
 * just a wrapper for Select from Circuit UI, you need to pass the
 * current country as value prop.
 */
const CountrySelect = ({ excludedCountries, name, ...props }) => {
	const { t } = useTranslation();
	return (
		<StyledSelect
			options={CountrySelectService.getCountryOptions(excludedCountries, t)}
			placeholder={t('billing_details.country.placeholder')}
			id={name}
			{...{ ...props, name }}
		/>
	);
};

CountrySelect.propTypes = {
	/**
   * A list of SumUp countries not to show.
   */
	excludedCountries: PropTypes.arrayOf(PropTypes.oneOf(allCountries)),
	/**
   * The name used for this select and its label.
   */
	name: PropTypes.string,
};

CountrySelect.defaultProps = {
	excludedCountries: [],
	name: 'countrySelect',
};

export default CountrySelect;
