import React from 'react';
import { useTranslation } from 'react-i18next';
import MaskInput from '../../input/maskInput';
import { spacing } from '@sumup/circuit-ui/legacy';

const CARD_NUMBER_MASK = [
	/\d/, /\d/, /\d/, /\d/, ' ',
	/\d/, /\d/, /\d/, /\d/, ' ',
	/\d/, /\d/, /\d/, /\d/, ' ',
	/\d/, /\d/, /\d/, /\d/, ' ',
	/\d/, /\d/, /\d/
];

const propagateChange = handler => e => {
	const { value } = e.target;

	return handler && handler(value.replace(/\s+/g, ''));
};

const cardNumberToTextValue = (cardNumber) => {
	const chunks = cardNumber.match(/.{1,4}/g) || [];

	return chunks.join(' ');
};

const CardNumberInput = ({ value, onChange, ...renderProps }) => {
	const textValue = cardNumberToTextValue(value);
	const { t } = useTranslation();

	return (<MaskInput
		label={t('payment_method.credit_card.card_number.title')}
		value={textValue}
		mask={CARD_NUMBER_MASK}
		onChange={propagateChange(onChange)}
		autoComplete='cc-number'
		css={spacing({ bottom: 'mega' })}
		{...renderProps}
	/>);
};

export default CardNumberInput;
