import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { InputRow, InputCol, Input } from '../../input';
import { Field } from 'react-final-form';
import { spacing } from '@sumup/circuit-ui/legacy';
import { useTranslation } from 'react-i18next';
import { composeValidators, required, ibanValidator, preventInjection } from '../../../utils/form-validators';
import MaskInput from '../../input/maskInput';
import { PAYMENT_SERVICE_PROVIDER } from '../../../constants';
import { Body } from '../../body';

const IBAN_MASK = [
	/[A-Za-z]/, /[A-Za-z]/, /\d/, /\d/,  ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ',
	/[A-Za-z0-9]/, /[A-Za-z0-9]/,
];

const LegalText = styled(Body)`
	${({ theme }) => `
		margin-bottom: ${theme.spacings.mega};
		color: var(--cui-fg-subtle);
	`}
`;

const DirectDebit = ({ onChange, countryCode, legalEntity }) => {
	const { t } = useTranslation();
	const [state, setState] = useState({
		firstName: '',
		lastName: '',
		iban: '',
	});
	const reflectStateChange = (field, value) => {
		const newState = {
			...state,
			[field]: value,
		};
		setState(newState);
		onChange(newState);
	};

	return (
		<div data-testid="direct-debit">
			<InputRow>
				<InputCol>
					<Field
						name="directDebitFirstName"
						validate={composeValidators(required(t('payment_method.direct_debit.first_name.field_name')), preventInjection)}
						defaultValue={state.firstName}
					>
						{({ meta, input }) => (
							<Input
								label={t('payment_method.direct_debit.first_name.title')}
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								autoComplete='given-name'
								{...input}
								onChange={(event) => {
									const { value } = event.target;
									reflectStateChange('directDebitFirstName', value);
									input.onChange && input.onChange(value);
								}}
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
				<InputCol>
					<Field
						name="directDebitLastName"
						validate={composeValidators(required(t('payment_method.direct_debit.last_name.field_name')), preventInjection)}
						defaultValue={state.lastName}
					>
						{({ meta, input }) => (
							<Input
								label={t('payment_method.direct_debit.last_name.title')}
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								autoComplete='family-name'
								{...input}
								onChange={(event) => {
									const { value } = event.target;
									reflectStateChange('directDebitLastName', value);
									input.onChange && input.onChange(value);
								}}
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>
			<InputRow>
				<InputCol>
					<Field
						name="iban"
						validate={composeValidators(
							required(t('payment_method.direct_debit.iban.field_name')),
							ibanValidator,
						)}
						defaultValue={state.iban}
					>
						{({ meta, input }) => (
							<MaskInput
								label={t('payment_method.direct_debit.iban.title')}
								placeholder={t('payment_method.direct_debit.iban.field_placeholder', { countryCode })}
								invalid={Boolean(meta.touched && meta.error)}
								validationHint={meta.touched && meta.error}
								mask={IBAN_MASK}
								{...input}
								onChange={(event) => {
									const { value } = event.target;
									const sanitizedValue = value.toUpperCase().trim();
									reflectStateChange('iban', sanitizedValue);
									input.onChange && input.onChange(sanitizedValue);
								}}
								css={spacing({ bottom: 'mega' })}
							/>
						)}
					</Field>
				</InputCol>
			</InputRow>
			<LegalText>
				{
					t('payment_method.direct_debit.legal_text', {
						legalEntity,
						paymentServiceProvider: PAYMENT_SERVICE_PROVIDER,
					})
				}
			</LegalText>
		</div>
	);
};

DirectDebit.propTypes = {
	onChange: PropTypes.func.isRequired,
	countryCode: PropTypes.string.isRequired,
	legalEntity: PropTypes.string.isRequired,
};

export default DirectDebit;
