import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

let isEnabled = false;

const SENSITIVE_URL_PARAMS = [
	'token',
	'addressLine1',
	'addressLine2',
	'city',
	'zip',
	'company',
	'vatNumber',
	'email',
	'phone',
	'firstName',
	'lastName'
];

export function init() {
	if (process.env.REACT_APP_SENTRY_ENABLED) {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			integrations: [
				new Integrations.BrowserTracing(),
			],
			tracesSampleRate: 1.0,
			environment: process.env.REACT_APP_ENV,
			beforeSend: (event, hint) => {
				if (event.request) {
					const url = new URL(event.request.url);
					const searchParams = new URLSearchParams(url.search);
					for (const sensitiveParam of SENSITIVE_URL_PARAMS) {
						if (searchParams.has(sensitiveParam)) {
							searchParams.delete(sensitiveParam);
						}
					}
					event.request.url = url.origin + url.pathname + '?' + searchParams.toString();
				}
				return event;
			}
		});

		isEnabled = true;
	}
}

export function track(err, context, state = 'unknown') {
	if (isEnabled) {
		Sentry.setTag('state', state);
		Sentry.captureException(err, context);
	} else {
		console.error(err);
	}
}

export function log(message) {
	if (isEnabled) {
		Sentry.captureMessage(message);
	} else {
		console.log(message);
	}
}

export function setUser(userAttributes) {
	if (isEnabled) {
		Sentry.setUser(userAttributes);
	} else {
		console.log(userAttributes);
	}
}

export default { init, track, log, setUser };

