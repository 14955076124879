import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, Button } from '@sumup/circuit-ui';
import { Info } from '@sumup/icons';

export const CheckoutForm = styled(Card)(({ theme }) => css`
	flex: 2;
	margin: 0 0 ${theme.spacings.giga} 0;
	${theme.mq.mega} {
		margin: 0 ${theme.spacings.giga} 0 0;
	}
`);

export const SubmitButton = styled(Button)`
	align-self: flex-start;
	margin-top: ${({ theme, isMobile }) => isMobile ? theme.spacings.mega : theme.spacings.exa};
`;

export const TooltipIcon = styled(Info)`
	cursor: help;
`;
