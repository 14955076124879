import wrapper from './api';
import { trace } from '../utils/notifyParent';
import { BillingError, ApiError } from '../utils/errors';
import { TRACING_EVENTS } from '../../shared/constants';

function billingService({ token }) {
	const onError = (error => {
		const serviceIsUnavailable = error instanceof BillingError
			&& (
				error.isNetworkError()
				|| error.isHTTPServiceUnavailable()
				|| error.isHTTPGatewayTimeout()
			);
		const details = error instanceof ApiError && error.details;

		if (serviceIsUnavailable) {
			trace(TRACING_EVENTS.BILLING_SERVICE_UNAVAILABLE, {
				details,
			});
		} else {
			trace(TRACING_EVENTS.SERVER_ERROR, {
				type: 'Billing service',
				reason: error.message,
				details,
			});
		}
	});
	const api = wrapper({
		request: {
			baseURL: '/client',
			withCredentials: false,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			errorHandled: true,
		},
		error: BillingError,
		onError,
	});

	return {
		getConfig,
		getCustomerDetails,
		prepareSubscriptionPurchase,
		purchaseSubscription,
		updateSubscription,
		getSubscriptionPriceDetails,
		getUpdateSubscriptionPriceDetails,
		preparePaymentMethodUpdate,
		updatePaymentMethod,
		updateBillingInfo,
		verifyPaymentInstrument,
		validateCouponCode,
		addDirectDebit
	};

	async function getConfig() {
		return api.get('/config');
	}

	async function getCustomerDetails() {
		return await api.get('/customer');
	}

	async function prepareSubscriptionPurchase({ billingDetails, redirectUrl }) {
		return api.post('/subscriptions/purchase/prepare', { billingDetails, redirectUrl });
	}

	async function purchaseSubscription({ planId, paymentMethodType, paymentMethodData, couponCode }) {
		return await api.post('/subscriptions/purchase', {
			planId,
			paymentMethod: {
				type: paymentMethodType,
				[paymentMethodType]: paymentMethodData
			},
			couponCode,
		});
	}

	async function getSubscriptionPriceDetails({ planId, customerCountryCode, vatNumber, couponCode }) {
		const url = `/subscriptions/plans/${planId}/price-details`;
		const query = {
			customerCountryCode,
			customerVatNumber: vatNumber,
			couponCode,
		};

		return await api.get(url, { params: query });
	}

	async function updateBillingInfo({ billingDetails }) {
		return await api.patch('/customer/billing-details', billingDetails);
	}

	async function preparePaymentMethodUpdate({ redirectUrl }) {
		return await api.post('/customer/payment-method/prepare', {
			redirectUrl
		});
	}

	async function updatePaymentMethod({ paymentMethodType, paymentMethodData }) {
		return await api.post('/customer/payment-method', {
			type: paymentMethodType,
			[paymentMethodType]: paymentMethodData
		});
	}

	async function updateSubscription({ subscriptionId, planId }) {
		return await api.post(`/subscriptions/${subscriptionId}/plans/${planId}`);
	}

	async function getUpdateSubscriptionPriceDetails({ subscriptionId, planId, vatNumber }) {
		const url = `/subscriptions/${subscriptionId}/plans/${planId}/price-details`;
		const query = {
			customerVatNumber: vatNumber,
		};

		return await api.get(url, { params: query });
	}

	async function verifyPaymentInstrument({ checkoutId }) {
		const url = '/customer/payment-method/verify';

		return await api.post(url, { checkoutId });
	}

	async function validateCouponCode({ couponCode, planId }) {
		const url = '/coupons/validate';

		return await api.post(url, { couponCode, planId });
	}

	async function addDirectDebit({ iban, company_name }) {
		const url = '/customer/direct-debit';
		const body = {
			company_name,
			iban,
			is_recurring: true
		};

		return await api.post(url, body);
	}
}

export default billingService;
