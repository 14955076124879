import styled from '@emotion/styled';

export const Index = styled('span')`
	display: inline-block;
	background: var(--cui-bg-highlight);
	background-image: linear-gradient(to right, var(--cui-bg-highlight) 0%, var(--cui-bg-highlight-hovered) 20%, var(--cui-bg-highlight) 40%, var(--cui-bg-highlight) 100%);
	animation: placeHolderShimmer 2s linear infinite;
	animation-fill-mode: forwards;
	background-repeat: no-repeat;
	user-select: none;
	cursor: progress;
	color: transparent;
	line-height: inherit;
	font-size: inherit;
	align-self: center;
	vertical-align: middle;

	@keyframes placeHolderShimmer {
		0% {
			background-position: -468px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}
`;

export const FullWidthShimmer =  styled(Index)`
	diplay: block;
	width: 100%;
`;
