import { allCountries } from '../../constants/country';

const getOptionLabel = (option) => option.label;

const sortByOptionLabel = (optionA, optionB) =>
	getOptionLabel(optionA).localeCompare(getOptionLabel(optionB));

const toSelectOption = (translate) => (country) => ({
	value: country,
	label: translate(`country.${country}`),
});

const isEnabledCountry = (excludedCountries) => {
	const exclusionHash = excludedCountries.reduce((acc, country) => ({
		...acc,
		[country]: country,
	}), {});

	return (country) => !exclusionHash[country];
};

export function getCountryOptions(excludedCountries, translate) {
	return allCountries
		.filter(isEnabledCountry(excludedCountries))
		.map(toSelectOption(translate))
		.sort(sortByOptionLabel);
}
