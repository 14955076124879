import React from 'react';
import PropTypes from 'prop-types';
import { WidgetError, RuntimeError } from '../utils/errors';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		const widgetError = error instanceof WidgetError
			? error
			: new RuntimeError('Unexpected runtime error', { ...error, errorInfo });

		widgetError.propagate();

		this.props.onError(widgetError);
	}

	render() {
		if (this.state.hasError) {
			return null;
		}
		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	onError: PropTypes.func.isRequired,
};
