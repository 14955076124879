import { NotificationInline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

const StyledNotificationInline = styled(NotificationInline)`
	h3, p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
`;

export { StyledNotificationInline as NotificationInline };