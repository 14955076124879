import { track } from '../utils/monitoring';

const mocks = new Map();
const tracks = new Map();

export const mockResponse = (url, response = {}) => {
	const { status, data, method } = response;

	mocks.set(url, {
		url,
		method,
		status,
		data,
	});
};

export const clearMock = (url, method) => {
	if (mocks.has(url) && mocks.get(url).method === method) {
		mocks.delete(url);
	}
};

export const trackRequest = (url, method) => new Promise(release => {
	if (!tracks.has(url)) {
		tracks.set(url, []);
	}
	tracks.get(url).push({ release, method });
});

export const removeTracker = (url, method) => {
	if (tracks.has(url) && track.get(url).method === method) {
		tracks.delete(url);
	}
};

export const reset = () => {
	mocks.clear();
	tracks.clear();
};


export const inject = (instance) => {
	instance.interceptors.request.use((config) => {
		const { url, method } = config;
		if (tracks.has(url)) {
			tracks.get(url).forEach((track) => {
				if (track.method === method || !track.method) {
					track.release(config);
				}
			});
		}
		if (mocks.has(url)) {
			const mock = mocks.get(url);
			if (mock.method === method || !mock.method) {
				const { status = 404, data = {} } = mock;
				const response = { status, data };

				config.adapter = () => new Promise((resolve, reject) => {
					status && status > 199 && status < 300
						? resolve({ config, response, data })
						: reject({ config, response });
				});
			}
		}
		return config;
	}, (error) => {
		return Promise.reject(error);
	});
};
