import React from 'react';
import TextMaskInput from 'react-text-mask';
import { Input } from './index';

const maskInput = ({
	mask,
	value,
	type,
	...props
}) => {
	return (<TextMaskInput
		render={(inputRef, { ...renderProps }) => (
			<Input
				ref={inputRef}
				value={value}
				type={type || 'text'}
				{...renderProps}
			/>
		)}
		mask={mask}
		guide={false}
		{...props}
	/>);
};

export default maskInput;
