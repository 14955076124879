import monitoring from './monitoring';

export class WidgetError extends Error {
	type = this.constructor.name;

	constructor(message, details = {}) {
		super(message);
		this.details = details;
	}

	addContext(context) {
		Object.assign(this.details, context);

		return this;
	}

	propagate() {
		monitoring.track(this, this.details, this.state);

		return this;
	}

	throw() {
		throw this;
	}

	serialize() {
		const { message, type, code, details } = this;

		return { message, type, code, details };
	}

	is(errorClass) {
		return this instanceof errorClass;
	}
}
export class InternalError extends WidgetError {}
export class RuntimeError extends InternalError {}

export class InitializationError extends InternalError {
	state = 'initialization'
}

export class MissingCustomerError extends InternalError {
	state = 'missing_customer'
}

export class MissingCustomerSubscriptionError extends InternalError {
	state = 'missing_customer_subscription'
}

export class VatUpdateError extends InternalError {
	state = 'vat_update'
}

export class BillingDetailsUpdateError extends InternalError {
	state = 'billing_details_update'
}

export class PaymentMethodVerificationError extends InternalError {
	state = 'payment_method_verification'
}

export class UpdateSubscriptionError extends InternalError {
	state = 'update_subscription'
}

export class PurchaseSubscriptionError extends InternalError {
	constructor(type, message, details = {}) {
		super(message, details);
		this.state = `purchase.${type}`;
	}
}

export class ChangePaymentDetailsError extends InternalError {
	constructor(type, message, details = {}) {
		super(message, details);
		this.state = `change_payment_details.${type}`;
	}
}

export class ApiError extends WidgetError {
	constructor(message, details = {}) {
		super(message);
		const { url, method, status, data = {} } = details;

		this.details = {
			url: hoistQueryParams(url, ['token']),
			method,
			status,
			data,
		};
	}

	isNetworkError() {
		return isNaN(this.details.status) || this.details.status === 0;
	}

	isHTTPBadRequest() {
		return this.details.status === 400;
	}
	isHTTPUnauthorized() {
		return this.details.status === 401;
	}
	isHTTPForbidden() {
		return this.details.status === 403;
	}
	isHTTPNotFoundError() {
		return this.details.status === 404;
	}
	isHTTPConflict() {
		return this.details.status === 409;
	}
	isHTTPBadGateway() {
		return this.details.status === 502;
	}
	isHTTPServiceUnavailable() {
		return this.details.status === 503;
	}
	isHTTPGatewayTimeout() {
		return this.details.status === 504;
	}
}

export class BillingError extends ApiError {
	get customerHasSubscription() {
		const { code } = this.details.data;

		return code === 'customerHasActiveSubscription';
	}

	get hasInvalidPayload() {
		const { code } = this.details.data;

		return code === 'schema';
	}
}
export class SumUpError extends ApiError {
	state = 'sumup-api'

	get hasInvalidCardData() {
		const { error_code } = this.details.data;

		return error_code === 'PAYMENT_INSTRUMENT_VALIDATION';
	}
}
export class ViesError extends ApiError {}

export class EPayError extends ApiError {}

export class SDKError extends WidgetError {}

export class CouponValidationError extends  ApiError {
	state = 'coupon_validation'
}

function hoistQueryParams(url, sensitiveData = []) {
	if (typeof url === 'string') {
		let output = url;
		for (const param of sensitiveData) {
			output = output.replace(new RegExp(`${param}=[^&]+`, 'g'), `${param}=***`);
		}
		return output;
	}

	return url;
}
