import styled from '@emotion/styled';
import { Hr } from '@sumup/circuit-ui';

/**
 * This overrides default <hr> styles to get a 1px line
 */
const Separator = styled(Hr)`
	border-width: ${({ theme }) => theme.borderWidth.kilo} 0 0 0;
`;

export default Separator;
